import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import "../../assets/css/Interfaces.scss";
import {
  Form,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Container,
  Tabs,
  Tab,
} from "react-bootstrap";
import { RefreshAccessToken } from "../../APIs/api-xibo";
import "../../assets/css/Gestionnaire.css";
import { useCookies } from "react-cookie";
import UploadFile from "../../components/Interfaces/UploadFile";
import FilesList from "../../components/Interfaces/FilesList";
import { BsXSquare, BsFillInfoCircleFill } from "react-icons/bs";
import ConfirmationModal from "../../components/ConfirmationModal";
import PrintsList from "../../components/Prints/PrintsList";
import UploadPrintFiles from "../../components/Prints/UploadPrintFiles";
import ViewInterf2 from "../../components/Interfaces/ViewInterf_2";
import { getValue, rotateImg } from "../../services/Utils";
import { GetAllConfigs, GetAllTemplates } from "../../services/PrintsServices";
import {
  DeleteInterface,
  GetAllInterfaces,
} from "../../services/InterfaceServices";
import PrintsTemplateForm from "../../components/Prints/PrintsTemplateForm";
import PrintsConfigs from "../../components/Prints/PrintsConfigs";
import LinksConfigNew from "../../components/Links/LinksConfigNew";
import LinksConfigMod from "../../components/Links/LinksConfigMod";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

function Interfaces(props) {
  const [fullData, setFullData] = useState([]);

  const [showAlertInterfFailed, setShowAlertInterfFailed] = useState(false);
  const [showAlertInterfSuccess, setShowAlertInterfSuccess] = useState(false);
  const [showIconesSec, setShowIconesSec] = useState(false);
  const [showNewLinksSec, setShowNewLinksSec] = useState(false);
  const [showModifySec, setShowModifySec] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPrintTemplate, setShowPrintTemplate] = useState(false);
  const [showPrintTmpMod, setShowPrintTmpMod] = useState(false);
  const [showPrintCustCreate, setShowPrintCustCreate] = useState(false);
  const [showPrintCustMod, setShowPrintCustMod] = useState(false);

  const [tempSelected, setTempSelected] = useState(false);

  const [showPrintIcs, setShowPrintIcs] = useState(false);
  const [showPrintFiles, setShowPrintFiles] = useState(false);

  const [interfToDelete, setInterfToDelete] = useState("");

  const [borneSelected, setBorneSelected] = useState("");
  const [dataBorneSelected, setDataBorneSelected] = useState({});

  const [configSelected, setConfigSelected] = useState();

  const cookies = useCookies(["access_token"]);
  const [Ccookies] = useCookies(["refresh_token"]);

  const [modData, setModData] = useState();
  const [templates, setTemplates] = useState([]);
  const [configs, setConfigs] = useState([]);

  const formRef = useRef(null);
  const formRef3 = useRef(null);

  const [alertMsg, setAlertMsg] = useState("Interface successfully deleted");

  const [key, setKey] = useState("config");

  useEffect(() => {
    if (Ccookies.user === undefined) {
      props.history.push("/xauth");
    } else if (Ccookies.user.userName === "xibo_admin") {
    } else if (Ccookies.user.userName == "media_lisa") {
    } else if (Ccookies.user.groups[0].group === "Annonceurs") {
      props.history.push("/adboard/home");
    } else if (Ccookies.user.groups[0].group !== "Medecins") {
      props.history.push("/xauth");
    }

    GetInterfaces();
  }, []);

  useEffect(async () => {
    if (cookies[0]["access_token"] === undefined) {
      // await RefreshAccessToken();
      // GetInterfaces();
      console.log("access token undefined in INTERFACES");
      RefreshAccessToken().then(() => {
        GetInterfaces();
      });
    }
    if (Ccookies.user === undefined) {
      props.history.push("/xauth");
    } else if (Ccookies.user.userName === "xibo_admin") {
    } else if (Ccookies.user.userName === "media_lisa") {
    } else if (Ccookies.user.groups[0].group === "Annonceurs") {
      props.history.push("/adboard/home");
    } else if (Ccookies.user.groups[0].group !== "Medecins") {
      props.history.push("/xauth");
    }
  });

  async function GetInterfaces() {
    const response = await GetAllInterfaces();
    if (response.error) {
      alert("Erreur lors de la récupération des interfaces");
    } else {
      setFullData(response.data);
    }
  }

  const IconsSectionButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowIconesSec(!showIconesSec);
          rotateImg("carret-iconsList");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Links Icons</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-iconsList"}
          ></i>
        </h4>
      </button>
    );
  };

  const LinksSectionButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowNewLinksSec(!showNewLinksSec);
          rotateImg("carret-newLinksList");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Créer une interface</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-newLinksList"}
          ></i>
        </h4>
      </button>
    );
  };

  const ModifySectionButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowModifySec(!showModifySec);
          rotateImg("carret-ModifyList");
          setDataBorneSelected([{}]);
          setBorneSelected("");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Modifier des interfaces</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-ModifyList"}
          ></i>
        </h4>
      </button>
    );
  };

  const iconsCRUD = () => {
    return (
      <div className="fileDivsBlocs">
        <div className="col-md-4 fileDivs forms-bg" id="fileFormDiv">
          <UploadFile />
        </div>
        <div className="col-md-7 fileDivs forms-bg" id="fileListDiv">
          <FilesList />
        </div>
      </div>
    );
  };

  const handleSelect = (event) => {
    setDataBorneSelected(null);
    setBorneSelected(null);
    setBorneSelected(event.target.value);

    setModData(
      setTemplate(
        fullData.filter((borne) => {
          return borne.NumeroBorne === event.target.value;
        })[0]
      )
    );

    setDataBorneSelected(
      fullData.filter((borne) => {
        return borne.NumeroBorne === event.target.value;
      })
    );
  };

  const BorneSelector = () => {
    return (
      <span className=" ">
        <Form className="G-form interf-forms" ref={formRef3}>
          <Row style={{ margin: "0" }} className="forms-bg">
            <Col>
              <Form.Group controlId="borneSelect" className="interfSectionsH3">
                <Form.Label>Numéro de la borne</Form.Label>
                <Form.Control
                  required
                  as="select"
                  defaultValue=""
                  size="sm"
                  onChange={handleSelect}
                >
                  <option></option>
                  {fullData.map((borne) => {
                    return (
                      <option key={borne.id} value={borne.NumeroBorne}>
                        {borne.NumeroBorne}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            {borneSelected ? (
              <Col
                xs={1}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "0",
                  alignItems: "center",
                }}
              >
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Suppression <strong>définitive</strong>
                    </Tooltip>
                  }
                >
                  <BsXSquare
                    className="delete-icons-interfaces-interf"
                    color={"red"}
                    onClick={() => {
                      setShowDeleteConfirm(true);
                      setInterfToDelete(dataBorneSelected[0].id);
                    }}
                  />
                </OverlayTrigger>
                <ConfirmationModal
                  key={"confMInterf"}
                  show={showDeleteConfirm}
                  closeModal={() => setShowDeleteConfirm(!showDeleteConfirm)}
                  message="Supprimer définitivement l'interface ?"
                  confirm={handleDeleteInterf}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
        {borneSelected ? (
          <LinksConfigMod dataBorne={dataBorneSelected} />
        ) : (
          <></>
        )}
      </span>
    );
  };

  async function handleDeleteInterf() {
    const deletion = await DeleteInterface(interfToDelete);
    if (deletion.error) {
      setShowAlertInterfFailed(true);
      alert("Erreur lors de la suppression de l'interface");
    } else {
      setShowAlertInterfSuccess(true);
      setShowDeleteConfirm(false);
      setDataBorneSelected(null);
      setBorneSelected(null);
      formRef3.current.reset();
      GetInterfaces();
    }
  }

  const PrintsIcsSectionButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowPrintIcs(!showPrintIcs);
          rotateImg("carret-printsIconsList");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Prints Icons</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-printsIconsList"}
          ></i>
        </h4>
      </button>
    );
  };

  const printsIconsCRUD = () => {
    return (
      <div className="fileDivsBlocs">
        <div className="col-md-4 fileDivs forms-bg" id="printsIcsFormDiv">
          <UploadPrintFiles type="icon" />
        </div>
        <div className="col-md-7 fileDivs forms-bg" id="printsIcsListDiv">
          <PrintsList type="icon" />
        </div>
      </div>
    );
  };

  const PrintsFilesSectionButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowPrintFiles(!showPrintFiles);
          rotateImg("carret-printsFilesList");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Prints Files</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-printsFilesList"}
          ></i>
        </h4>
      </button>
    );
  };

  const printsFilesCRUD = () => {
    return (
      <div className="fileDivsBlocs">
        <div className="col-md-4 fileDivs forms-bg" id="printsFilesFormDiv">
          <UploadPrintFiles type="file" />
        </div>
        <div className="col-md-7 fileDivs forms-bg" id="printsFilesListDiv">
          <PrintsList type="file" />
        </div>
      </div>
    );
  };

  const PrintsTemplateSectButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowPrintTemplate(!showPrintTemplate);
          rotateImg("carret-printsTemplCreate");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Créer un Template de Print</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-printsTemplCreate"}
          ></i>
        </h4>
      </button>
    );
  };

  const PrintsTemplModSectButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowPrintTmpMod(!showPrintTmpMod);
          rotateImg("carret-printsTemplMod");
          if (showPrintTmpMod) {
            setTempSelected(null);
          }
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">Modifier un Template de Print</span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-printsTemplMod"}
          ></i>
        </h4>
      </button>
    );
  };

  const PrintsTempModSelector = () => {
    return (
      <Form.Control
        size="sm"
        as="select"
        id="templateSelector"
        onChange={setSelectedTemplate}
        className="mt-3 "
      >
        <option key="default" className="text-center font-italic">
          --- Select Template ---
        </option>
        {templates.data && templates.data.data.length > 0 ? (
          templates.data.data.map((template) => {
            return (
              <option key={template._id} value={template._id}>
                {template.name}
              </option>
            );
          })
        ) : (
          <></>
        )}
      </Form.Control>
    );
  };

  const setSelectedTemplate = (event) => {
    setTempSelected(
      templates.data.data.filter((temp) => temp._id === event.target.value)[0]
    );
  };

  const PrintsCustCreateSectButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowPrintCustCreate(!showPrintCustCreate);
          rotateImg("carret-printsCustomCreate");
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">
            Create Bornes Custom Prints Configuration
          </span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-printsCustomCreate"}
          ></i>
        </h4>
      </button>
    );
  };

  const PrintsCustModSectButton = () => {
    return (
      <button
        className="btn G-title interfSectionsH3"
        type="button"
        onClick={() => {
          setShowPrintCustMod(!showPrintCustMod);
          rotateImg("carret-printsCustomMod");
          if (showPrintCustMod) {
            setConfigSelected(null);
          }
        }}
        id="icons-title-btn "
      >
        <h4
          className="data-titles-tab"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span id="btn-title-span">
            Modify Bornes Custom Prints Configuration
          </span>
          <i
            className="bi bi-caret-down-fill rotate h4"
            id={"carret-printsCustomMod"}
          ></i>
        </h4>
      </button>
    );
  };

  const PrintsCustoModSelector = () => {
    return (
      <Form.Control
        size="sm"
        as="select"
        id="configSelector"
        onChange={setSelectedConfig}
        className="mt-3"
      >
        <option key="default" className="text-center font-italic">
          --- Select Borne Config ---
        </option>
        {configs.data &&
        configs.data.printsConfigs &&
        configs.data.printsConfigs.length > 0 ? (
          configs.data.printsConfigs.map((conf) => {
            return (
              <option key={conf._id} value={conf._id}>
                {conf.borne_id}
              </option>
            );
          })
        ) : (
          <></>
        )}
      </Form.Control>
    );
  };

  const setSelectedConfig = (event) => {
    setConfigSelected(
      configs.data.printsConfigs.filter(
        (conf) => conf._id === event.target.value
      )[0]
    );
  };

  useEffect(() => {
    if (showPrintTmpMod) {
      async function fetchData() {
        const allTemplates = await GetAllTemplates();
        setTemplates(allTemplates);
      }
      fetchData();
    }
  }, [showPrintTmpMod]);

  function setTemplate(dataTarget) {
    let formatedData = [];
    if (dataTarget) {
      if (dataTarget instanceof HTMLFormControlsCollection) {
        for (let i = 1; i <= 6; i++) {
          formatedData.push({
            img: getValue(`image${i}`, dataTarget),
            url: getValue(`link${i}`, dataTarget),
          });
        }
        // setLinksNb(dataTarget["nbLinks"].value);
      } else {
        for (let i = 1; i <= 6; i++) {
          formatedData.push({
            img: getValue(`I${i}`, dataTarget),
            url: getValue(`L${i}`, dataTarget),
          });
        }
        // setLinksNb(dataTarget.NbLiens);
      }
      // setLinks(formatedData);
      return formatedData;
    }
  }

  useEffect(() => {
    formRef.current && formRef.current.reset();
  }, [dataBorneSelected]);

  useEffect(() => {
    if (showPrintCustMod) {
      async function fetchData() {
        const allConfigs = await GetAllConfigs();
        setConfigs(allConfigs);
      }
      fetchData();
    }
  }, [showPrintCustMod]);

  return (
    <Container id="interfacesPage">
      <h1 className="mt-1 mb-3 text-center">Interfaces Dashboard</h1>
      <h2 className="">Interfaces Links</h2>
      <Container>
        <Container id="filesSection">
          {IconsSectionButton()}
          {showIconesSec ? iconsCRUD() : <></>}
        </Container>
        <Container id="linksSection">
          {LinksSectionButton()}
          {showNewLinksSec ? (
            <span>
              <Container>
                <LinksConfigNew />
              </Container>
            </span>
          ) : (
            <></>
          )}
        </Container>
        <Container id="intSection">
          {ModifySectionButton()}
          {showModifySec ? (
            <>
              <div className="container"> {BorneSelector()} </div>
              {/* {borneSelected ? (
                <div>
                  {dataBorneSelected && modData ? (
                    <ViewInterf2 dataProps={modData} />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )} */}
            </>
          ) : (
            <></>
          )}
        </Container>
      </Container>
      <h2 className="">Interfaces Prints</h2>
      <Container className="mr-2" style={{ marginBottom: "100px" }}>
        <Tabs
          defaultActiveKey="config"
          id="uncontrolled-tab-interf-prints"
          className="justify-content-center mb-3 uncontrolled-tab-interf-prints"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="files"
            title="Prints Files & Icons"
            className={key == "files" ? "color-black" : "color-grey"}
          >
            <Container id="printsIcnsSect">
              {PrintsIcsSectionButton()}
              {showPrintIcs ? printsIconsCRUD() : <></>}
            </Container>
            <Container id="printsFilesSect">
              {PrintsFilesSectionButton()}
              {showPrintFiles ? printsFilesCRUD() : <></>}
            </Container>
          </Tab>
          <Tab eventKey="templates" title="Prints Templates">
            <Container id="printsFilesSect">
              {PrintsTemplateSectButton()}
              {showPrintTemplate ? (
                <div className="mt-1 mb-5"> {<PrintsTemplateForm />}</div>
              ) : (
                <></>
              )}
            </Container>
            <Container id="printsFilesSect">
              {PrintsTemplModSectButton()}
              {showPrintTmpMod ? (
                <>
                  <div className="mt-1 mb-5 ">{PrintsTempModSelector()}</div>
                  {tempSelected ? (
                    <div className="mt-1 mb-5">
                      {
                        <PrintsTemplateForm
                          dataTemplate={tempSelected}
                          setShowSection={setShowPrintTmpMod}
                        />
                      }
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Container>
          </Tab>
          <Tab eventKey="config" title="Prints Configuration">
            <Container id="printsFilesSect">
              {PrintsCustCreateSectButton()}
              {showPrintCustCreate ? (
                <div className="mt-1 mb-5">
                  <PrintsConfigs
                    resetSelector={() => {
                      setShowPrintCustCreate(false);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </Container>
            <Container id="printsFilesSect">
              {PrintsCustModSectButton()}
              {showPrintCustMod ? (
                <>
                  <div className="mt-1 mb-5">{PrintsCustoModSelector()}</div>
                  {configSelected ? (
                    <div className="mt-1 mb-5">
                      <PrintsConfigs
                        dataConfig={configSelected}
                        resetSelector={() => {
                          setConfigSelected(null);
                          setShowPrintCustMod(false);
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Container>
          </Tab>
        </Tabs>
      </Container>
      {/* <Container>
        <Container className="mt-4">
          <h3 className="">Prints Files & Icons</h3>
        </Container>
        <Container id="printsIcnsSect">
          {PrintsIcsSectionButton()}
          {showPrintIcs ? printsIconsCRUD() : <></>}
        </Container>
        <Container id="printsFilesSect">
          {PrintsFilesSectionButton()}
          {showPrintFiles ? printsFilesCRUD() : <></>}
        </Container>
        <Container className="mt-4">
          <h3 className="">Prints Templates</h3>
        </Container>
        <Container id="printsFilesSect">
          {PrintsTemplateSectButton()}
          {showPrintTemplate ? (
            <div className="mt-1 mb-5"> {<PrintsTemplateForm />}</div>
          ) : (
            <></>
          )}
        </Container>
        <Container id="printsFilesSect">
          {PrintsTemplModSectButton()}
          {showPrintTmpMod ? (
            <>
              <div className="mt-1 mb-5">{PrintsTempModSelector()}</div>
              {tempSelected ? (
                <div className="mt-1 mb-5">
                  {
                    <PrintsTemplateForm
                      dataTemplate={tempSelected}
                      setShowSection={setShowPrintTmpMod}
                    />
                  }
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Container>
        <Container className="mt-4">
          <h3 className="">Prints Configuration</h3>
        </Container>
        <Container id="printsFilesSect">
          {PrintsCustCreateSectButton()}
          {showPrintCustCreate ? (
            <div className="mt-1 mb-5">
              <PrintsConfigs
                resetSelector={() => {
                  setShowPrintCustCreate(false);
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </Container>
        <Container id="printsFilesSect">
          {PrintsCustModSectButton()}
          {showPrintCustMod ? (
            <>
              <div className="mt-1 mb-5">{PrintsCustoModSelector()}</div>
              {configSelected ? (
                <div className="mt-1 mb-5">
                  <PrintsConfigs
                    dataConfig={configSelected}
                    resetSelector={() => {
                      setConfigSelected(null);
                      setShowPrintCustMod(false);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Container>
      </Container> */}

      {/* --------------- ALERTS SECTION --------------- */}
      <Container id="alerts-forms">
        <Alert
          show={showAlertInterfSuccess}
          key={"interfaceSuccess"}
          variant="success"
          className="alertsForms"
          id="AlertSuccess"
        >
          {/* <Alert.Heading>Interface successfully uploaded</Alert.Heading> */}
          <Alert.Heading>{alertMsg}</Alert.Heading>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlertInterfSuccess(false)}
              variant="outline-success"
            >
              OK
            </Button>
          </div>
        </Alert>
        <Alert
          show={showAlertInterfFailed}
          key={"interfaceFail"}
          variant={"danger"}
          className="alertsForms"
        >
          <Alert.Heading>Interface not created</Alert.Heading>
          <p>
            Something went wrong during creation. <br />
            Try to : <br />
            - Check the kiosk number <br />
            - Check if this interface already exist <br />
            <br /> <br />
            If the problem persists, contact the{" "}
            <b>
              <a
                href="mailto: richard@lisaconnect.fr"
                target="_blank"
                rel="noreferrer"
              >
                administrator
              </a>
              .
            </b>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowAlertInterfFailed(false)}
                variant="outline-danger"
              >
                OK
              </Button>
            </div>
          </p>
        </Alert>
      </Container>
      <ModalForms />
    </Container>
  );
}

export default Interfaces;
