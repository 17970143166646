export const validDevices = ["borne", "screen", "repeat-screen", "totem"];
export const jours = [
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi",
  "dimanche",
];

class InfosBorne {
  constructor({
    _id = null,
    borne = "",
    etablissement = "",
    refclient = "Default",
    adresse = "",
    latitude = "",
    longitude = "",
    service = "Default",
    etage = "Default",
    heures = {},
    devices = [],
    pictures = [],
    status = "waiting",
    createdAt = null,
    updatedAt = null,
  } = {}) {
    this._id = _id;
    this.borne = borne;
    this.etablissement = etablissement;
    this.refclient = refclient;
    this.adresse = adresse;
    this.latitude = latitude;
    this.longitude = longitude;
    this.service = service;
    this.etage = etage;
    this.heures = this.initializeHeures(heures);
    this.devices = devices;
    this.pictures = pictures;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  initializeHeures(heures) {
    const defaultHoraire = { start: null, end: null };
    return jours.reduce((acc, jour) => {
      acc[jour] = heures[jour] || { ...defaultHoraire };
      return acc;
    }, {});
  }

  isHoraireValid(jour) {
    const horaire = this.heures[jour];
    if (horaire.start && horaire.end) {
      const [startHour, startMinute] = horaire.start.split(":").map(Number);
      const [endHour, endMinute] = horaire.end.split(":").map(Number);
      return startHour * 60 + startMinute < endHour * 60 + endMinute;
    }
    return horaire.start === null && horaire.end === null;
  }

  addDevice(device) {
    // const validDevices = ["borne", "screen", "repeat-screen", "totem"];
    if (validDevices.includes(device) && !this.devices.includes(device)) {
      this.devices.push(device);
    }
  }

  replaceDevices(devices) {
    this.devices = devices;
  }

  removeDevice(device) {
    this.devices = this.devices.filter((d) => d !== device);
  }

  addPicture(pictureUrl) {
    if (pictureUrl && !this.pictures.includes(pictureUrl)) {
      this.pictures.push(pictureUrl);
    }
  }

  removePicture(pictureUrl) {
    this.pictures = this.pictures.filter((url) => url !== pictureUrl);
  }
}

export default InfosBorne;
