import axios from "axios";

export const Cdomain = ".lisaconnect.fr";
// export const Cdomain = "localhost";
//
export const XapiURL = "https://xapi.lisaconnect.fr";
// export const XapiURL = "http://localhost:8080";
export const XiboURL = "https://connexion.lisaconnect.fr";
// export const XiboURL = "http://localhost";
export const GestionURL = "https://gestion.lisaconnect.fr";
// export const GestionURL = "http://localhost:3000";
export const GamesURL = "https://games.lisaconnect.fr";
// export const GamesURL = "http://localhost:3001";

const Xibo = axios.create({
  baseURL: `${XiboURL}/api`,
});

export const Xpress = axios.create({
  baseURL: `${XapiURL}/api`,
  withCredentials: true,
  crossDomain: true,
  origin: GestionURL,
});

export const XpressNoApi = axios.create({
  baseURL: `${XapiURL}`,
  withCredentials: true,
  crossDomain: true,
  origin: GestionURL,
});

let accessToken = "";

let refreshStateToken = (token) => null;

//Fonction pour refresh l'access token dans les url de video qui n'utilisent pas Axios, appelée dans les components
export let UpdateAccessState = (refreshStateFunction) => {
  refreshStateToken = refreshStateFunction;
};

export const redirect_uri = `${GestionURL}/auth/xlogin`;
export const xiboLogUri = `${XiboURL}`;
export const clientId = "m4PzqBnqt5aP1Amh3ogprYO2xWFGFGo1ci2d9RQy";

export const RefreshAccessToken = async () => {
  // console.log("refreshing access token");
  let cookie = getCookie("refresh_token");
  Xpress.get(`/token/refresh?cookie=${cookie}`).then((response) => {
    setCookie("access_token", "Bearer " + response.data.access_token, {
      SameSite: "None",
      Secure: true,
      path: "/",
      // domain: Cdomain, // Notez le point avant le nom de domaine
    });
    // console.log(
    //   "response.data.DATA1.access_token: " + response.data?.DATA1?.access_token
    // );
    // console.log("response.data.access_token: " + response.data.access_token);
    setCookie("refresh_token", response.data.refresh_token, {
      SameSite: "None",
      Secure: true,
      path: "/",
      maxAge: 172800,
      // domain: Cdomain, // Notez le point avant le nom de domaine
    });
    accessToken = "Bearer " + response.data.access_token;
    refreshStateToken(accessToken);
  });
};

export const RefreshAccessToken2 = async () => {
  // console.log("refreshing access token 2");

  let cookie = getCookie("refresh_token");
  // console.log("cookie: " + cookie);
  Xpress.get(`/token/login?code=${cookie}`)
    .then((response) => {
      setCookie("refresh_token", response.data.DATA1.refresh_token, {
        path: "/",
        // sameSite: "strict",
        SameSite: "None",
        secure: true,
        maxAge: 172800,
      });
      console.log(
        "2 response.data.DATA1.access_token: " +
          response.data.DATA1.access_token
      );
      setCookie("access_token", "Bearer " + response.data.DATA1.access_token, {
        path: "/",
        // sameSite: "strict",
        SameSite: "None",
        secure: true,
        maxAge: 3600,
      });
      setCookie("user", JSON.stringify(response.data.DATA2), {
        path: "/",
        // sameSite: "strict",
        SameSite: "None",
        secure: true,
      });
      refreshStateToken("Bearer " + response.data.DATA1.access_token);
      return true;
    })
    .catch((error) => {
      console.log("ERREUR DANS REFRESH ACCESS TOKEN 2");
      console.log(error);
      return false;
    });
};

Xpress.interceptors.request.use(
  (config) => {
    const at = getCookie("access_token");
    // console.log("access token: " + at);
    config.headers["Authorization"] = getCookie("access_token");
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = GestionURL;
    config.headers["Access-Control-Allow-Credentials"] = true;
    config.headers["Access-Control-Allow-Methods"] =
      "GET, POST, PUT, DELETE, PATCH, OPTIONS";
    config.headers["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type, Accept, Authorization, Code, Cookie, Set-Cookie, Get-Cookie, Bearer";
    return config;
  },
  (error) => {
    Promise.reject(error);
    console.log(
      "ERREUR DANS L'INTERCEPTOR      -------------------------------------------"
    );
  }
);

// Xpress.interceptors.response.use((response) => {
//   console.log("INTERCEPTOR !!!!!!");
//   console.log("response status: " + response.status);
//   if (response.status === 401) {
//     RefreshAccessToken();
//   }
//   return response;
// });

Xpress.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("INTERCEPTOR XPRESS API-XIBO !!!!!!");
    if (error.response.status === 401) {
      console.log("INTERCEPTOR XPRESS API-XIBO !!!!!!");
      console.log("error.response", error.response);
      const tkn = await RefreshAccessToken();

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
XpressNoApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("INTERCEPTOR NO API !!!!!!");
    const tkn = await RefreshAccessToken();

    return Promise.reject(error);
  }
);

XpressNoApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = getCookie("access_token");
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = GestionURL;
    config.headers["Access-Control-Allow-Credentials"] = true;
    config.headers["Access-Control-Allow-Methods"] =
      "GET, POST, PUT, DELETE, PATCH, OPTIONS";
    config.headers["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type, Accept, Authorization, Code, Cookie, Set-Cookie, Get-Cookie, Bearer";
    return config;
  },
  (error) => {
    Promise.reject(error);
    console.log(
      "ERREUR DANS L'INTERCEPTOR      -------------------------------------------"
    );
  }
);

Xibo.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = getCookie("access_token");
    // console.log("Interceptor config:  ");
    // console.log(config.headers);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Xibo.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    console.log("interceptor API XIBO");

    if (error.response.status === 401 || error.response.status === 400) {
      if (
        !originalRequest._retry &&
        originalRequest.url !== "/authorize/access_token"
      ) {
        originalRequest._retry = true;
        await RefreshAccessToken();
        // console.log("PRINT DE XIBO(ORIGINALREQUEST)");
        // console.log(Xibo(originalRequest));
        return Xibo(originalRequest);
      } else {
        window.location.href = `${GestionURL}/xauth`;
      }
    }

    return Promise.reject(error);
  }
);

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      // console.log(c.substring(name.length, c.length));
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//for duration : 3600 = one hour
function setCookie(cname, cvalue) {
  // document.cookie = cname + "=" + cvalue + ";" + ";path=/;samesite=strict";
  document.cookie =
    cname + "=" + cvalue + ";" + ";path=/;SameSite=None;Secure=true;";
}

export default Xibo;
