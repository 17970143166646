import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";

import "../../assets/css/Docboard.css";
import Sidebar from "../../components/Sidebar";
import TerminalView from "../../components/Doc/TerminalView";
import Xibo, {
  RefreshAccessToken,
  UpdateAccessState,
  XiboURL,
} from "../../APIs/api-xibo";
import VideoPlayer from "../../components/VideoPlayer";
import { useCookies } from "react-cookie";
import LoadingModal from "../../components/LoadingModal";

function DocHome(props) {
  const [cookies] = useCookies(["user"]);
  const [borneActive, setBorneActive] = useState(0);
  const [bornes, setBornes] = useState([]);
  const [annonces, setAnnonces] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [showLoading, setShowLoading] = useState({
    show: false,
    message: "",
  });
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (showLoading.message !== "") {
      setAlertMessage(showLoading.message);
      let show = showLoading.show;
      setShowLoading({ show: show, message: "" });
    }
  }, [showLoading]);

  // useEffect(() => {
  //   UpdateAccessState(setAccessToken);
  //   RefreshAccessToken();
  // }, []);

  useEffect(() => {
    if (accessToken !== "") {
      setShowLoading({ show: true, message: "" });
      const start = async () => {
        try {
          let resDisplays = await Xibo.get("/display");
          let arrBornes = [];
          let videos = [];

          let dateNow = new Date();
          let minutes = ("0" + dateNow.getMinutes()).slice(-2);
          let seconds = ("0" + dateNow.getSeconds()).slice(-2);

          let stringDateNow =
            dateNow.getFullYear() +
            "-" +
            dateNow.getMonth() +
            "-" +
            dateNow.getDate() +
            " " +
            dateNow.getHours() +
            ":" +
            minutes +
            ":" +
            seconds;
          for (let i = 0; i < resDisplays.data.length; i++) {
            arrBornes.push(resDisplays.data[i].display);
            let resSchedule = await Xibo.get(
              `/schedule/${resDisplays.data[i].displayGroupId}/events?date=${stringDateNow}`
            );
            let vidsForThisBorne = [];
            let numPub = 1;
            for (let j = 0; j < resSchedule.data.events.length; j++) {
              let vidName = resSchedule.data.events[j].campaign;
              if (vidName == "Code borne") continue;
              let vids = await Xibo.get("/library?media=" + vidName);

              if (vids.data.length === 0) continue;

              if (vids.data[0].ownerId == cookies.user.userId) {
                continue; //On ne veut que les vidéos des annonceurs
              }

              vidsForThisBorne.push(
                <Card key={j} className="video-card mt-3">
                  <Card.Header>
                    <b>{numPub + " - " + vids.data[0].name.split("__")[2]}</b>
                  </Card.Header>
                  <Card.Body>
                    <VideoPlayer
                      idVid={j}
                      src={
                        // "https://connexion.lisaconnect.fr/api/library/download/" +
                        `${XiboURL}/api/library/download/` +
                        vids.data[0].mediaId +
                        "/video?access_token=" +
                        accessToken.split(" ")[1]
                      }
                    />
                  </Card.Body>
                </Card>
              );
              numPub++;
            }
            videos.push(vidsForThisBorne);
          }
          setShowLoading({ show: false, message: "" });
          setBornes(arrBornes);
          setAnnonces(videos);
        } catch (error) {
          console.log(error);
        }
      };
      start();
    }
  }, [accessToken]);

  const handleSelectBorne = (eventKey, e) => {
    setBorneActive(eventKey);
  };

  return (
    <div>
      <Sidebar
        items={bornes}
        title="Parc de bornes"
        keyActive={borneActive}
        onSelect={(eventKey, e) => handleSelectBorne(eventKey, e)}
      />
      <div className="content">
        <LoadingModal show={showLoading.show} />
        <TerminalView
          className="content"
          title={bornes[borneActive]}
          content={annonces[borneActive]}
        />
      </div>
    </div>
  );
}

export default DocHome;
