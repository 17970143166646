import Xibo, { Xpress } from "../APIs/api-xibo";

const PrinterServices = {
  GetAllPrinterAdmin: async () => {
    try {
      const resp = await Xpress.get("/printer/all");
      return { success: true, data: resp.data.data, error: null };
    } catch (error) {
      console.error(error);
      return { success: false, data: null, error: error };
    }
  },

  GetPrinterInfo: async function (kioskName) {
    try {
      const resp = await Xpress.get("/printer/" + kioskName);
      return { success: true, data: resp.data.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  GetPrinterStatus: async function (display, deviceName) {
    try {
      const resp = await Xpress.get(
        "/printer/status/" + display + "&" + deviceName
      );
      if (resp.status === 299) {
        return { success: false, data: resp.data, error: resp.data.error };
      }
      return { success: true, data: resp.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  GetPrinterAddress: async function (display, deviceName) {
    try {
      const resp = await Xpress.get(
        "/leads/bornes/" + display + "&" + deviceName
      );
      if (resp.status === 299) {
        return { success: false, data: resp.data, error: resp.data.error };
      }
      return { success: true, data: resp.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  AskBorneInfosMod: async function (display, deviceName) {
    try {
      const resp = await Xpress.post(
        // "/leads/bornes/mod/" + display + "&" + deviceName
        "/leads/bornes/mod/" + display
      );
      if (resp.status === 299) {
        return { success: false, data: resp.data, error: resp.data.error };
      }
      return { success: true, data: resp.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  ValidBorneInfos: async function (display, deviceName) {
    try {
      const resp = await Xpress.post(
        // "/leads/bornes/valid/" + display + "&" + deviceName
        "/leads/bornes/valid/" + display
      );
      if (resp.status === 299) {
        return { success: false, data: resp.data, error: resp.data.error };
      }
      return { success: true, data: resp.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  AskValidBorneInfos: async function (display, deviceName) {
    try {
      const resp = await Xpress.post(
        // "/leads/bornes/valid/" + display + "&" + deviceName
        "/leads/bornes/waiting/" + display
      );
      if (resp.status === 299) {
        return { success: false, data: resp.data, error: resp.data.error };
      }
      return { success: true, data: resp.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  formatContactForEmail: function (printersContacts) {
    const {
      contact_principal,
      contact_2,
      contact_3,
      borne,
      etablissement,
      refclient,
    } = printersContacts;

    const formatContact = (contact, title) => {
      if (
        !contact ||
        (!contact.nom &&
          !contact.prenom &&
          !contact.email &&
          !contact.telephone &&
          !contact.commentaire)
      ) {
        return "";
      }
      return `
      ${title}<br>
      Nom: ${contact.nom || "N/A"}<br>
      Prénom: ${contact.prenom || "N/A"}<br>
      Email: ${contact.email || "N/A"}<br>
      Téléphone: ${contact.telephone || "N/A"}<br>
      Commentaire: ${contact.commentaire || "N/A"}<br>
    `;
    };

    const principalContact = formatContact(
      contact_principal,
      "Contact Principal:"
    );
    const secondaryContact1 = formatContact(contact_2, "Contact Secondaire 1:");
    const secondaryContact2 = formatContact(contact_3, "Contact Secondaire 2:");

    const formattedEmailBody = `
    Établissement: ${etablissement}<br>
    Référence Client: ${refclient}<br>
    Borne: ${borne}<br><br>

    ${principalContact}
    <br><br>
    ${secondaryContact1}
    <br><br>
    ${secondaryContact2}
  `;

    return formattedEmailBody.trim();
  },
};

export default PrinterServices;
