import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Switch, Route, Redirect } from "react-router-dom";

import routes from "../routes";
import NavTop from "../components/NavTop";
import { GetMyDisplays } from "../services/GetDisplays";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrinterAddress,
  setKiosks,
  setPrintersContacts,
  setPrintersStatus,
  clearKiosks,
  clearStore,
} from "../store/slices/kioskSlice";
import DocInfos from "../views/DocBoard/DocInfos";
import PrinterServices from "../services/PrinterServices";
import { logoutUser } from "../store/slices/authSlice";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/docboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/docboard" to="/docboard/annonces" />
  </Switch>
);
function Medecin(props) {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["refresh_token"]);
  const [isOk, setOk] = useState(false);

  const { printersContacts, kiosks } = useSelector((state) => state.kiosk);

  // useEffect(() => {
  //   console.log("printersContacts");
  //   if (printersContacts && printersContacts != {}) {
  //     console.log("printersContacts", printersContacts);
  //     if (
  //       printersContacts.etablissement?.includes("LisaConnect") ||
  //       printersContacts.etablissement?.includes("default")
  //     ) {
  //       console.log("LisaConnect default");
  //       // props.history.push("/docboard");
  //     }
  //   }
  // }, [printersContacts]);

  useEffect(() => {
    // console.log("cookies", cookies);
    if (cookies.user === undefined) {
      props.history.push("/xauth");
    } else if (cookies.user.userName === "xibo_admin") {
      setOk(true);
    } else if (cookies.user.groups[0].group === "Annonceurs") {
      props.history.push("/adboard/home");
    } else if (cookies.user.groups[0].group !== "Medecins") {
      props.history.push("/xauth");
    } else {
      setOk(true);
    }
  }, []);

  const handleDisconnection = (e) => {
    // document.cookie =
    //   "refresh_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // document.cookie =
    //   "access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // document.cookie = "user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    removeCookie("refresh_token", { path: "/" });
    removeCookie("access_token", { path: "/" });
    removeCookie("user", { path: "/" });

    // dispatch(clearKiosks());
    dispatch(clearStore());
    dispatch(logoutUser());

    props.history.push("/logout");
  };

  async function getDisp() {
    const displays = await GetMyDisplays(cookies.access_token);
    dispatch(setKiosks(displays));
  }

  useEffect(() => {
    if (cookies.user !== undefined) {
      getDisp();
    }
  }, []);

  async function GetPrinterContacts() {
    const printerContacts = await PrinterServices.GetPrinterInfo(
      // kiosks[0].display || kiosks[0].deviceName
      kiosks[0].display
    );

    if (printerContacts.success && printerContacts.data) {
      // console.log("printerContacts.data", printerContacts.data);
      dispatch(setPrintersContacts(printerContacts.data));
    } else {
      console.error("error", printerContacts);
    }
  }

  async function GetPrinterInfos() {
    const printerStatus = await PrinterServices.GetPrinterStatus(
      kiosks[0].display,
      kiosks[0].deviceName
    );
    if (printerStatus.success && printerStatus.data) {
      dispatch(setPrintersStatus(printerStatus.data));
    } else {
      console.error("error", printerStatus);
    }
  }

  async function GetPrinterAddress() {
    const printerAddress = await PrinterServices.GetPrinterAddress(
      kiosks[0].display,
      kiosks[0].deviceName
    );

    if (printerAddress.success && printerAddress.data) {
      // console.log("printerAddress.data.data", printerAddress.data.data);

      dispatch(setPrinterAddress(printerAddress.data.data));
    } else {
      console.error("error", printerAddress);
    }
  }

  useEffect(() => {
    if (kiosks.length === 0) {
    } else if (kiosks.length === 1) {
      GetPrinterContacts();
      GetPrinterInfos();
      GetPrinterAddress();
    } else if (kiosks.length > 1) {
      console.log("kiosks length > 1");
    }
  }, [kiosks]);

  return (
    <div>
      {isOk && (
        <div>
          <NavTop
            pageName={props.location.pathname}
            layout="/docboard"
            onClick={(e) => handleDisconnection(e)}
          />
          <div>{switchRoutes}</div>
          <DocInfos />
        </div>
      )}
    </div>
  );
}

export default Medecin;
