import React, { useEffect, useState } from "react";

import "../assets/css/Docboard.css";
import "../assets/css/VideoPlayer.css";

function VideoPlayer(props) {
  const [paused, setPaused] = useState(true);
  const [length, setLength] = useState(null);
  const [formattedLength, setFormattedLength] = useState(null);
  const [currentTime, setCurrentTime] = useState("00:00:00");
  const [formattedTime, setFormattedTime] = useState(null);

  useEffect(() => {
    String.prototype.toHHMMSS = function () {
      let sec_num = parseInt(this, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return /*hours + ":" +*/ minutes + ":" + seconds;
    };
    let interCurrTime = setInterval(() => setCurrentTime(currTime()), 10);
    let interLength = setInterval(() => setLength(duration()), 10);

    return () => {
      clearInterval(interCurrTime);
      clearInterval(interLength);
    };
  }, []);

  useEffect(() => {
    if (parseInt(currentTime) >= parseInt(length)) {
      const v = document.getElementById("v" + props.idVid);
      v.pause();
      setPaused(true);
    }
  }, [currentTime, length]);

  const play = () => {
    if (!document.getElementById("v" + props.idVid)) return null;
    duration();
    const v = document.getElementById("v" + props.idVid);
    const play_pause = document.querySelector(".play_pause");

    setPaused(!paused);
    if (paused === true && parseInt(currentTime) < parseInt(length)) {
      v.play();
      setPaused(false);
    } else {
      v.pause();
      setPaused(true);
    }
  };

  const duration = () => {
    if (!document.getElementById("v" + props.idVid)) return null;
    let dur = null;
    let pformattedLength = null;

    if (dur === null) {
      dur = 0;
    }

    if (document.getElementById("v" + props.idVid).duration < 25) {
      dur = document.getElementById("v" + props.idVid).duration;
      dur = dur.toFixed();
      pformattedLength = dur.toHHMMSS();
    } else {
      dur = 25;
      pformattedLength = "00:25";
    }
    setLength(dur);
    setFormattedLength(pformattedLength);
    return dur;
  };

  const currTime = () => {
    if (!document.getElementById("v" + props.idVid)) return null;
    let cur = document.getElementById("v" + props.idVid).currentTime;
    cur = cur.toFixed();
    let fTime = cur.toHHMMSS();

    setCurrentTime(cur);
    setFormattedTime(fTime);

    if (parseInt(cur) === parseInt(length)) {
      setPaused(true);
    }

    return cur;
  };

  const customTime = () => {
    if (!document.getElementById("v" + props.idVid)) return null;
    const time_range = document.querySelector(".time_range");
    document.getElementById("v" + props.idVid).currentTime = time_range.value;

    setCurrentTime(time_range.value);
  };

  return (
    <div className="VideoPlayer">
      <video
        onClick={play}
        id={"v" + props.idVid}
        className="v"
        width="500"
        height="auto"
      >
        <source src={props.src} type="video/mp4" />
      </video>

      <div className="controls">
        <button onClick={play} className="play_pause_btn">
          <svg
            className={paused ? "svg_play_pause" : "hide"}
            viewBox="0 0 232.153 232.153"
          >
            <path
              d="M203.791,99.628L49.307,2.294c-4.567-2.719-10.238-2.266-14.521-2.266
                    c-17.132,0-17.056,13.227-17.056,16.578v198.94c0,2.833-0.075,16.579,17.056,16.579c4.283,0,9.955,0.451,14.521-2.267
                    l154.483-97.333c12.68-7.545,10.489-16.449,10.489-16.449S216.471,107.172,203.791,99.628z"
            />
          </svg>

          <svg
            className={paused ? "hide" : "svg_play_pause"}
            viewBox="0 0 232.153 232.153"
          >
            <path
              d="M80.543,0H35.797c-9.885,0-17.898,8.014-17.898,17.898v196.883
                    c0,9.885,8.013,17.898,17.898,17.898h44.746c9.885,0,17.898-8.013,17.898-17.898V17.898C98.44,8.014,90.427,0,80.543,0z M196.882,0
                    h-44.746c-9.886,0-17.899,8.014-17.899,17.898v196.883c0,9.885,8.013,17.898,17.899,17.898h44.746
                    c9.885,0,17.898-8.013,17.898-17.898V17.898C214.781,8.014,206.767,0,196.882,0z"
            />
          </svg>
        </button>

        <span className="time">
          <span className="video_time">{formattedTime}</span>
          <span> / </span>
          <span className="video_length">{formattedLength}</span>
        </span>

        <input
          type="range"
          className="time_range"
          onChange={customTime}
          value={currentTime}
          step={0.1}
          min={0}
          max={length}
        />
      </div>
    </div>
  );
}
export default VideoPlayer;
