const { Xpress, default: Xibo } = require("../APIs/api-xibo");

const AutoVidServices = {
  GetAllVideoXibo: async function () {
    try {
      const xibovids = await Xpress.get("/auto/xibovideos");
      //   console.log("xibovids", xibovids.data);
      return { success: true, data: xibovids.data, error: null };
    } catch (error) {
      console.log("error", error);
      return { success: false, data: null, error: error };
    }
  },
  GetAllXiboMedias: async function () {
    try {
      const xibomeds = await Xibo.get(
        "/library?media=lib__&start=1&length=1000"
      );
      return { success: true, data: xibomeds.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  GetAllXiboUnusuedMedias: async function () {
    try {
      const xibomeds = await Xpress.get("/auto/xibovideos/medias");
      return { success: true, data: xibomeds.data, error: null };
    } catch (error) {
      console.error("error", error);
      return { success: false, data: null, error: error };
    }
  },
  PostNewXiboVideo: async function (body) {
    try {
      const response = await Xpress.post("/auto/xibovideo", body);
      return { success: true, data: response.data, error: null };
    } catch (error) {
      console.error("Error sending data", error);
      return { success: false, data: null, error: error };
    }
  },
  GetAllBoucleTemps: async function () {
    try {
      const templates = await Xpress.get("/auto/templates");
      return { success: true, data: templates.data, error: null };
    } catch (error) {
      console.error("Error getting templates", error);
      return { success: false, data: null, error: error };
    }
  },
  PostBoucleTemp: async function (data) {
    try {
      const patch = await Xpress.post(`/auto/template`, data);
      return { success: true, data: patch.data, error: null };
    } catch (error) {
      console.error("Error patching template", error);
      return { success: false, data: null, error: error };
    }
  },
  PatchBoucleTemp: async function (id, data) {
    try {
      const patch = await Xpress.patch(`/auto/template/${id}`, data);
      return { success: true, data: patch.data, error: null };
    } catch (error) {
      console.error("Error patching template", error);
      return { success: false, data: null, error: error };
    }
  },
  DeleteBoucleTemp: async function (id) {
    try {
      const patch = await Xpress.delete(`/auto/template/${id}`);
      return { success: true, data: patch.data, error: null };
    } catch (error) {
      if (error.response.status !== 409) {
        console.error("Error patching template", error.response);
      }
      return { success: false, data: null, error: error };
    }
  },
  DeleteXiboVideo: async function (id) {
    try {
      const response = await Xpress.delete("/auto/xibovideo/" + id);
      return { success: true, data: response.data, error: null };
    } catch (error) {
      return { success: false, data: null, error: error };
    }
  },
};

module.exports = AutoVidServices;
