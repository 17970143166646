import { useState } from "react";
import axios from "axios";
import GetIpClient from "./getIp";
import { XapiURL } from "../APIs/api-xibo";

export async function GetDisplays(code) {
  const Xpress = axios.create({
    baseURL: `${XapiURL}/api`,
    withCredentials: true,
    crossDomain: true,
    // origin: "https://gestion.lisaconnect.fr",
    origin: "*",
  });
  // let clientIp = await GetIpClient();
  // let videoName = mediaName;
  // const current = new Date();
  // const date = `${current.getDate()}/${
  //   current.getMonth() + 1
  // }/${current.getFullYear()}`;
  // const time =
  //   current.getHours() +
  //   ":" +
  //   current.getMinutes() +
  //   ":" +
  //   current.getSeconds();

  let proof;
  try {
    let displays = await Xpress.get(`user/displays?data=${code}`);
    if (proof.status === 201 || proof.status === 200) {
      console.log("Proof created");
      return true;
    } else {
      console.log("Proof not created");
      return "No displays found";
    }
  } catch (error) {
    return error;
  }
}

export async function GetMyDisplays(code) {
  const Xpress = axios.create({
    baseURL: `${XapiURL}/api`,
    withCredentials: true,
    crossDomain: true,
    origin: "*",
  });

  try {
    let displays = await Xpress.get(`user/displays?data=${code}`);
    return displays.data?.Data;
  } catch (error) {
    console.error(error);
    return [];
  }
}
