import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import "../../assets/css/login.css";
import axios from "axios";

import { useCookies } from "react-cookie";
import { Cdomain, XapiURL } from "../../APIs/api-xibo";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/slices/authSlice";

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function XiboLogin(props) {
  const dispatch = useDispatch();

  const Xpress = axios.create({
    baseURL: `${XapiURL}/api`,
    withCredentials: true,
    crossDomain: true,
  });
  const [cookies, setCookie] = useCookies(["refresh_token"]);

  useEffect(() => {
    let code = new URLSearchParams(props.location.search).get("code");
    console.log("code", code);
    try {
      Xpress.get(`/token/login?code=${code}`).then((response) => {
        // dispatch({ type: "SET_USER", payload: response.data.DATA2 });
        dispatch(loginUser(response.data));
        setCookie("refresh_token", response.data.DATA1.refresh_token, {
          path: "/",
          // sameSite: "strict",
          SameSite: "None",
          secure: true,
          maxAge: 172800,
          // domain: Cdomain,
        });
        setCookie(
          "access_token",
          "Bearer " + response.data.DATA1.access_token,
          {
            path: "/",
            // sameSite: "strict",
            SameSite: "None",
            secure: true,
            maxAge: 3600,
            // domain: Cdomain,
          }
        );
        setCookie("user", JSON.stringify(response.data.DATA2), {
          path: "/",
          // sameSite: "strict",
          SameSite: "None",
          secure: true,
          // domain: Cdomain,
        });
        props.history.push("/");
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <div className="center">
        <Loader
          className="center"
          type="TailSpin"
          // color="#00BFFF"
          color="#f8b5a8"
          height={100}
          width={100}
        />
      </div>
    </div>
  );
}

export default XiboLogin;
