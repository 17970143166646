import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Container,
  Col,
  Alert,
  InputGroup,
  Row,
  Modal,
  ListGroup,
  Badge,
} from "react-bootstrap";
import { Xpress } from "../../APIs/api-xibo";
import {
  BsPhone,
  BsGeo,
  BsFillTrashFill,
  BsArrowCounterclockwise,
  BsPlus,
  BsTrashFill,
  BsCheck,
  BsExclamationOctagon,
} from "react-icons/bs";

import { rotateImg } from "../../services/Utils";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrinterAddress,
  setPrintersContacts,
} from "../../store/slices/kioskSlice";
import PrinterServices from "../../services/PrinterServices";
import { FaBusinessTime, FaHourglassEnd } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import InfosBorne, {
  validDevices,
  jours,
} from "../../models/bornesInfos.model";

function BornesFormSimply({ mode = "admin" }) {
  const dispatch = useDispatch();
  const { printerAddress, kiosks } = useSelector((state) => state.kiosk);

  const [allAddress, setAllAddress] = useState([]);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  const [lastError, setLastError] = useState(null);
  const [invalidFields, setInvalidFields] = useState({});

  const [showForm, setShowForm] = useState(false); // To control the display of FormContact
  const [isEditMode, setIsEditMode] = useState(false); // To determine if the form is in edit mode or create mode
  const [selectedContactId, setSelectedContactId] = useState("empty"); // To store the selected contact's ID

  // ------------------------------------------------

  // let searchTimer = null; // Variable pour stocker le timer
  const searchTimerRef = useRef(null); // Utilisation de useRef pour le timer

  const emptyFormData = {
    borne: "",
    etablissement: "",
    refclient: "",
    adresse: "",
    latitude: "",
    longitude: "",
    service: "",
    etage: "",
    heures: {
      lundi: {
        start: null,
        end: null,
      },
      mardi: {
        start: null,
        end: null,
      },
      mercredi: {
        start: null,
        end: null,
      },
      jeudi: {
        start: null,
        end: null,
      },
      vendredi: {
        start: null,
        end: null,
      },
      samedi: {
        start: null,
        end: null,
      },
      dimanche: {
        start: null,
        end: null,
      },
    },
    devices: [],
    status: "",
  };

  // const [formData, setFormData] = useState(emptyFormData);
  const [formData, setFormData] = useState(new InfosBorne(emptyFormData));

  const [filteredAddresses, setFilteredAddresses] = useState([]);

  const [isAdmin, setIsAdmin] = useState(mode === "admin");

  const isAdminOrEditing = () => isAdmin || isEditMode;

  const searchAddress = async (query) => {
    if (typeof query !== "string" || !query.trim()) return;

    try {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${query}&format=json&countrycodes=fr`
      );
      setFilteredAddresses(data);
    } catch (error) {
      console.error("Error while fetching addresses:", error);
    }
  };

  const handleSearch = (e) => {
    const newQuery = e.target.value;
    // setFormData((prevState) => ({
    //   ...prevState,
    //   adresse: newQuery,
    // }));
    setFormData(
      (prevState) =>
        new InfosBorne({
          ...prevState,
          adresse: newQuery,
        })
    );
    if (newQuery.length > 2) {
      clearTimeout(searchTimerRef.current); // Annuler le timer précédent
      searchTimerRef.current = setTimeout(() => searchAddress(newQuery), 500); // Définir un nouveau timer
    }
  };

  const regexPatterns = {
    // borne: /^[a-zA-Z]-[a-zA-Z0-9]{3}-[0-9]{6}$/,
    borne: /^[a-zA-Z]-[a-zA-Z0-9]{3}-[0-9]{6}$/,
    refclient: /^.{4,}$/,
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
    telephone: /^(?:\d{10}|\d{2}[-.\s]\d{2}[-.\s]\d{2}[-.\s]\d{2}[-.\s]\d{2})$/,
  };

  function checkDataInputs() {
    let isValid = true;
    let newInvalidFields = {};

    // Check borne
    // if (!regexPatterns.borne.test(formData.borne)) {
    //   isValid = false;
    //   newInvalidFields.borne = true;
    // }

    // Check refclient
    if (!regexPatterns.refclient.test(formData.refclient)) {
      isValid = false;
      newInvalidFields.refclient = true;
    }

    setInvalidFields(newInvalidFields);
    return isValid;
  }

  const resetForm = () => {
    setShowForm(false);
    setIsEditMode(false);
    setSelectedContactId("");
    setInvalidFields({});
    // setFormData(emptyFormData);
    setFormData(new InfosBorne(emptyFormData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const keys = name.split(".");
    setFormData((prevState) => {
      let newState = new InfosBorne({ ...prevState });
      let nestedState = newState;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        if (!nestedState[key]) {
          nestedState[key] = {};
        }

        nestedState = nestedState[key];
      }

      nestedState[keys[keys.length - 1]] = value;

      return newState;
    });
  };

  const handleChangeTime = (e) => {
    const { name, value } = e.target;

    // Format the value to "hh:mm"
    const formattedValue = formatTime(value);

    const keys = name.split(".");
    setFormData((prevState) => {
      // let newState = { ...prevState };
      let newState = new InfosBorne({ ...prevState });
      let nestedState = newState;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        if (!nestedState[key]) {
          nestedState[key] = {};
        }

        nestedState = nestedState[key];
      }

      nestedState[keys[keys.length - 1]] = formattedValue;

      return newState;
    });
  };

  const formatTime = (value) => {
    // Remove any non-numeric characters
    const cleaned = value.replace(/\D/g, "");

    if (cleaned.length === 0) {
      return ""; // No input
    } else if (cleaned.length < 2) {
      return cleaned; // Only hours inputted
    } else if (cleaned.length === 2) {
      return cleaned + ":"; // Add colon after hours
    } else if (cleaned.length === 3) {
      return cleaned.slice(0, 2) + ":" + cleaned.slice(2, 3);
    } else if (cleaned.length === 4) {
      const cleanedWithColon = cleaned.slice(0, 2) + ":" + cleaned.slice(2, 4);

      if (isValidTime(cleanedWithColon)) {
        return cleanedWithColon; // Return valid time
      } else {
        return "23:59"; // Default to max valid time
      }
    } else {
      return;
    }
  };

  const isValidTime = (value) => {
    return /^([01]\d|2[0-3]):([0-5]\d)$/.test(value);
  };

  const handleAddDevices = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );

    formData.replaceDevices(selectedOptions); // Exemple : Ajoute un écran
    setFormData(new InfosBorne(formData)); // Force un re-render en recréant l'objet
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // TODO: Make an API call to your backend to save the data
    if (checkDataInputs() == true) {
      if (!isEditMode) {
        try {
          const resp = await Xpress.post(
            `/leads/bornes/${formData.borne}`,
            formData
          );
          if (resp.status == 299) {
            // console.log("299", resp.data);
            setLastError(resp.data.error);
            setShowAlertFailed(true);
          } else {
            // update the list of contacts
            setAllAddress((prevState) => [...prevState, resp.data.data]);
            setShowAlertSuccess(true);
            resetForm();
          }
        } catch (error) {
          console.error(error);
          setLastError(error.message || error);
          setShowAlertFailed(true);
        }
      } else {
        try {
          const resp = await Xpress.put(
            `/leads/bornes/${formData.borne}`,
            formData
          );
          if (resp.status == 299) {
            setLastError(resp.data.error);
            setShowAlertFailed(true);
          } else {
            // update the value of the contact in the list
            const newAllContacts = allAddress.map((contact) => {
              if (contact._id === resp.data.data._id) {
                return resp.data.data;
              }
              return contact;
            });
            setAllAddress(newAllContacts);
            // dispatch(setPrintersContacts(newAllContacts));
            dispatch(setPrinterAddress(newAllContacts));
            setShowAlertSuccess(true);
            resetForm();
          }
        } catch (error) {
          console.error(error);
          setLastError(error.message || error);
          setShowAlertFailed(true);
        }
      }
    }
  };

  const handleSelect = (event) => {
    const selectedId = event.target.value;
    if (selectedId) {
      const selectedAddress = allAddress.find(
        (contact) => contact._id === selectedId
      );
      setFormData(new InfosBorne(selectedAddress));
      setIsEditMode(true);
      setShowForm(true);
      setSelectedContactId(selectedId);
    }
  };

  async function GetAllLeads() {
    try {
      const resp = await Xpress.get("/leads/bornes");
      setAllAddress(resp.data.data);
    } catch (error) {
      console.error(error);
      setLastError(error.message || error);
      setShowAlertFailed(true);
    }
  }

  const GetHoursBadge = (start, end) => {
    switch (true) {
      case start == null && end == null:
        return BadgeTemplate("Fermé", "secondary");

      default:
        return BadgeTemplate({ start, end }, "success");
    }
  };

  const BadgeTemplate = (hours, varP) => {
    // return (
    //   <Col>
    //     <Badge variant={varP}>
    //       {hours === "Fermé" ? hours : `${hours?.start} - ${hours?.end}`}
    //     </Badge>
    //   </Col>
    // );
    return (
      <span style={{ fontSize: "smaller" }}>
        {hours === "Fermé" ? hours : `${hours?.start} - ${hours?.end}`}
      </span>
    );
  };

  async function AskForInfosMods() {
    try {
      const mod = await PrinterServices.AskBorneInfosMod(formData.borne);
      if (!mod.success) {
        setLastError(mod.error);
        setShowAlertFailed(true);
      } else {
        // console.log("mod", mod);
        // console.log("allAddress", allAddress);
        // // update the value of the contact in the list
        const newAllAddress = allAddress.map((contact) => {
          if (contact._id === mod.data.data._id) {
            return mod.data.data;
          }
          return contact;
        });
        setAllAddress(newAllAddress);
        dispatch(setPrinterAddress(newAllAddress));
        setShowAlertSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setLastError(error.message || error);
      setShowAlertFailed(true);
    }
  }

  async function AskForValidInfos() {
    try {
      const mod = await PrinterServices.AskValidBorneInfos(formData.borne);
      if (!mod.success) {
        setLastError(mod.error);
        setShowAlertFailed(true);
      } else {
        // console.log("mod.data", mod.data);
        // console.log("allAddress", allAddress);
        // update the value of the contact in the list
        const newAllAddress = allAddress.map((contact) => {
          if (contact._id === mod.data.data._id) {
            return mod.data.data;
          }
          return contact;
        });
        setAllAddress(newAllAddress);
        dispatch(setPrinterAddress(newAllAddress));
        setShowAlertSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setLastError(error.message || error);
      setShowAlertFailed(true);
    }
  }

  async function ValidInfosBornes() {
    try {
      const mod = await PrinterServices.ValidBorneInfos(formData.borne);
      if (!mod.success) {
        setLastError(mod.error);
        setShowAlertFailed(true);
      } else {
        // console.log("mod.data", mod.data);
        // console.log("allAddress", allAddress);
        // update the value of the contact in the list
        const newAllAddress = allAddress.map((contact) => {
          if (contact._id === mod.data.data._id) {
            return mod.data.data;
          }
          return contact;
        });
        setAllAddress(newAllAddress);
        dispatch(setPrinterAddress(newAllAddress));
        setShowAlertSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setLastError(error.message || error);
      setShowAlertFailed(true);
    }
  }

  useEffect(() => {
    if (mode === "doc" && printerAddress) {
      setIsAdmin(false);

      // setFormData(printerAddress);
      setFormData(new InfosBorne(printerAddress));
      setShowForm(true);
      setIsEditMode(true);
    } else if (mode === "admin") {
      setIsAdmin(true);
      GetAllLeads();
    }
  }, [mode, printerAddress]);

  return (
    <>
      {isAdmin ? (
        <section className="contact-actions-tools">
          <Row style={{ margin: "0" }} className="contact-form-actions">
            <Col xs="4">
              <Button
                // variant="outline-info"
                variant={
                  showForm && isEditMode === false ? "info" : "outline-info"
                }
                style={{ margin: "auto" }}
                onClick={() => {
                  if (showForm) {
                    resetForm();
                  } else {
                    setFormData(new InfosBorne(emptyFormData));
                    // setFormData(emptyFormData);
                    setIsEditMode(false);
                    setShowForm(true);
                    setSelectedContactId("");
                    invalidFields != {} && setInvalidFields({});
                  }
                }}
              >
                <BsPlus style={{ marginRight: "10px" }} />
                Nouvelle Borne
              </Button>
            </Col>

            <Col style={{ alignSelf: "center" }}>
              <Form.Group
                controlId="borneSelect"
                className="interfSectionsH3"
                style={{ margin: 0 }}
              >
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {/* <Form.Label size="sm">Numéro de la borne</Form.Label> */}
                      N° borne
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    required
                    as="select"
                    // defaultValue=""
                    size="sm"
                    onChange={handleSelect}
                    value={selectedContactId}
                  >
                    <option key={"empty"} value={""}></option>
                    {allAddress.map((borne) => {
                      return (
                        <option key={borne._id} value={borne._id}>
                          {borne.borne}
                        </option>
                      );
                    })}
                  </Form.Control>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs="1" style={{ alignSelf: "center" }}>
              {showForm ? (
                <Button size="sm" onClick={resetForm} variant="warning">
                  <BsArrowCounterclockwise />
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </section>
      ) : (
        <></>
      )}
      <section></section>
      {showForm && (
        <Form className="contact-form-actions" onSubmit={handleSubmit}>
          {isAdmin ? (
            <div
              style={{
                padding: "1vw 2vw",
                border: "1px solid orange",
                borderRadius: "8px",
                marginBottom: "16px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <div style={{ marginBottom: "5px" }}>
                <BsExclamationOctagon
                  color="orange"
                  style={{ marginRight: "5px" }}
                />
                <b>Informations importantes</b>
              </div>
              <div style={{ fontSize: "smaller", padding: "0 2vw" }}>
                - Le nom de borne doit être <u>unique</u>.
                <br />- Le nom de borne renseigné doit <u>
                  correspondre
                </u> au <u>nom réel</u> de la borne (<i>à la majuscule près</i>
                ).
              </div>
            </div>
          ) : (
            <></>
          )}
          <Form.Row>
            {isAdmin ? (
              <Form.Group as={Col} controlId="borne">
                <Form.Label>Borne *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <BsGeo />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="borne"
                    placeholder="ex: B-2v1-220000"
                    value={formData.borne}
                    onChange={handleChange}
                    isInvalid={invalidFields.borne}
                    disabled={isEditMode || !isAdmin}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Format Borne incorrecte. Format type: B-2v1-220000
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            ) : (
              <> </>
            )}
            {isAdmin ? (
              <Form.Group as={Col} controlId="refclient">
                <Form.Label>Ref Client (Axonaut) *</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    name="refclient"
                    placeholder="ex: 13270481 - Visible dans l'url d'Axonaut"
                    value={formData.refclient}
                    onChange={handleChange}
                    isInvalid={invalidFields.refclient}
                    aria-describedby="refClientHelpBlock"
                    disabled={!isAdmin}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Format RefClient incorrecte. Longeur min 4 caractères.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            ) : (
              <></>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={4} controlId="etablissement">
              <Form.Label>Etablissement *</Form.Label>
              <Form.Control
                type="text"
                name="etablissement"
                placeholder="ex: Clinique Tzanck"
                value={formData.etablissement}
                onChange={handleChange}
                disabled={!isAdmin}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="address">
              <Form.Label>Adresse *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Adresse de la borne"
                value={formData.adresse}
                onChange={handleSearch}
                disabled={!isAdmin}
                required
              />
              <ListGroup>
                {filteredAddresses.map((address, index) => (
                  <ListGroup.Item key={index}>
                    <span
                      onClick={() => {
                        setFormData(
                          (prevState) =>
                            new InfosBorne({
                              ...prevState,
                              adresse: address.display_name,
                              latitude: address.lat,
                              longitude: address.lon,
                            })
                        );
                        setFilteredAddresses([]);
                      }}
                    >
                      {address.display_name}
                    </span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="service">
              <Form.Label>Service *</Form.Label>
              <Form.Control
                type="text"
                name="service"
                placeholder="Service de la salle d'attente"
                value={formData.service}
                onChange={handleChange}
                disabled={!isAdmin}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs={2} controlId="etage">
              <Form.Label>Étage *</Form.Label>
              <Form.Control
                type="text"
                name="etage"
                placeholder="Étage de la salle d'attente"
                value={formData.etage}
                onChange={handleChange}
                disabled={!isAdmin}
                required
              />
            </Form.Group>

            {/* <Form.Group as={Col} controlId="devices">
              <Form.Label>Type d'équipement *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Détails des équipements"
                value={formData.devices}
                onChange={handleAddDevice}
                disabled={!isAdmin}
                required
              />
            </Form.Group> */}
            <Form.Group as={Col} xs={4} controlId="devices">
              <Form.Label>Type d'équipement *</Form.Label>
              <Form.Control
                as="select"
                placeholder="Détails des équipements"
                value={
                  formData.devices.length > 0
                    ? formData.devices[0]
                    : formData.devices
                }
                onChange={handleAddDevices}
                disabled={!isAdmin}
                // multiple
                required
              >
                {validDevices.map((device) => (
                  <option key={device} value={device}>
                    {device}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="heures">
              <Form.Label>Horaires *</Form.Label>
              {/* <Form.Control
                type="text"
                name="heures"
                placeholder="Service de la salle d'attente"
                value={formData.heures}
                onChange={handleChange}
                disabled={!isAdmin}
                required
              /> */}

              <Row
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                {formData.heures &&
                  Object.entries(formData.heures)
                    .sort(([jourA], [jourB]) => {
                      return jours.indexOf(jourA) - jours.indexOf(jourB);
                    })
                    .map(([jour, horaires]) => {
                      const { start, end } = horaires;
                      const startName = `heures.${jour}.start`;
                      const endName = `heures.${jour}.end`;

                      return (
                        <Col key={jour}>
                          <div className="text-center">
                            {jour.charAt(0).toUpperCase() + jour.slice(1)}
                          </div>
                          <div className="text-center mr-1 ml-1">
                            {isAdmin ? (
                              <>
                                <input
                                  type="text"
                                  name={startName}
                                  value={start || ""}
                                  onChange={handleChangeTime}
                                  placeholder="Début"
                                  className="form-control"
                                />
                                <input
                                  type="text"
                                  name={endName}
                                  value={end || ""}
                                  onChange={handleChangeTime}
                                  placeholder="Fin"
                                  className="form-control"
                                />
                              </>
                            ) : (
                              GetHoursBadge(start, end)
                            )}
                          </div>
                        </Col>
                      );
                    })}
              </Row>
              {isAdmin ? (
                <Form.Text id="passwordHelpBlock" muted>
                  Format des horaires : hh:mm . Laisser vide si fermé.
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
          </Form.Row>

          {isAdmin ? (
            <Container style={{ textAlign: "center" }}>
              {/* <Button
              variant={"outline-danger"}
              className="mr-5"
              onClick={() => setModalShow(true)}
            >
              <BsTrashFill /> Supprimer le contact ?
            </Button> */}
              {formData.status !== "waiting" && (
                <Button
                  variant="primary"
                  className="mr-3"
                  onClick={() => AskForValidInfos()}
                >
                  <BsCheck /> Ask User Validation
                </Button>
              )}
              <Button variant="success" type="submit">
                <BsCheck /> Submit
              </Button>
            </Container>
          ) : (
            <></>
          )}
          {!isAdmin ? (
            <Container style={{ textAlign: "center" }}>
              <Button
                variant={"warning"}
                className="mr-5"
                onClick={() => AskForInfosMods()}
                hidden={printerAddress.status == "mod-asked"}
              >
                <MdEdit /> Demande de modifications
              </Button>
              <Button
                variant="outline-secondary"
                hidden={printerAddress.status !== "mod-asked"}
                disabled
              >
                <FaHourglassEnd /> Attente de modifications
              </Button>
              <Button
                variant="success"
                onClick={() => ValidInfosBornes()}
                hidden={
                  printerAddress.status == "mod-asked" ||
                  printerAddress.status == "ok"
                }
              >
                <BsCheck /> Valider les informations
              </Button>
            </Container>
          ) : (
            <></>
          )}
        </Form>
      )}
      {showAlertFailed ? (
        <section
          className="alerts-forms"
          onClick={() => {
            setShowAlertFailed(false);
            setLastError(null);
          }}
        >
          <Alert
            show={showAlertFailed}
            key={"fileFail"}
            variant={"danger"}
            className="alertsForms"
          >
            <Alert.Heading>Erreur lors de l'enregistrement</Alert.Heading>
            <p>
              <br />
              <div>
                Error details : <b>{lastError}</b>
              </div>
              <br />
            </p>
            <hr />
            <p>
              If the problem persists, contact the{" "}
              <b>
                <a href="mailto: richard@lisaconnect.fr" target="_blank">
                  administrator
                </a>
                .
              </b>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    setShowAlertFailed(false);
                    setLastError(null);
                  }}
                  variant="outline-danger"
                >
                  OK
                </Button>
              </div>
            </p>
          </Alert>
        </section>
      ) : (
        <></>
      )}
      {showAlertSuccess ? (
        <section
          className="alerts-forms"
          onClick={() => setShowAlertSuccess(false)}
        >
          <Alert
            show={showAlertSuccess}
            variant="success"
            className="alertsForms"
            id="AlertSuccessFile"
          >
            <Alert.Heading>Borne's Informations success</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => setShowAlertSuccess(false)}
                variant="outline-success"
                size="sm"
                className="btn-icon-list"
              >
                OK
              </Button>
            </div>
          </Alert>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}

export default BornesFormSimply;
