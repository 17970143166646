import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kiosks: [],
  displayGroups: [],
  printersContacts: {},
  printersStatus: [],
  printerAddress: null,
};

const kioskSlice = createSlice({
  name: "kiosk",
  initialState,
  reducers: {
    setKiosks: (state, action) => {
      state.kiosks = action.payload;
    },
    setDisplayGroups: (state, action) => {
      state.displayGroups = action.payload;
    },
    setPrintersContacts: (state, action) => {
      state.printersContacts = action.payload;
    },
    setPrintersStatus: (state, action) => {
      state.printersStatus = action.payload;
    },
    setPrinterAddress: (state, action) => {
      state.printerAddress = action.payload;
    },
    clearStore: (state) => {
      state.kiosks = [];
      state.displayGroups = [];
      state.printersContacts = {};
      state.printersStatus = [];
      state.printerAddress = null;
    },
    clearKiosks: (state) => {
      state.kiosks = [];
    },
  },
});

export const {
  setKiosks,
  setDisplayGroups,
  setPrintersContacts,
  setPrintersStatus,
  setPrinterAddress,
  clearStore,
  clearKiosks,
} = kioskSlice.actions;
export default kioskSlice.reducer;
