import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import "../../../assets/css/Docboard.css";
import VideoPlayer from "../../VideoPlayer";
import Xibo from "../../../APIs/api-xibo";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useEffect } from "react";
// import { XiboURL } from "../../../APIs/api-xibo";
import { Container } from "react-bootstrap";
import ConfirmationModal from "../../ConfirmationModal";
import { XapiURL, GestionURL } from "../../../APIs/api-xibo";
import GetIpClient from "../../../services/getIp";
import axios from "axios";
import DisplaysProofs from "../../../services/DisplaysProofs";

function VideoModal(props) {
  const [cookies] = useCookies("user");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirm2, setShowConfirm2] = useState(true);

  const displayTags = () => {
    let tags = props.annonce.data.tags.split(",");

    let ret = tags.map((tag) => {
      return (
        <Badge key={tag} className="ml-2" variant="dark">
          {tag}
        </Badge>
      );
    });

    return ret;
  };

  // Diffusion d'une vidéo par un MEDECIN sur la bibliothèque : PART 1
  // -> on verifie si le medecin a le droit de diffuser une annonce
  const uploadAnnonce = () => {
    let layoutIndex = 1;
    Xibo.get("/layout").then((res) => {
      let indexLayouts = [];
      if (res.status === 200) {
        // console.log("------------ RES.DATA de /layout ------------");
        // console.log(res.data);
        // console.log("------------ FIN RES.DATA de /layout ------------");
        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].publishedStatus == "Published" &&
            res.data[i].ownerId == cookies.user.userId
          ) {
            // console.log("------------ PREMIER IF PASSE ------------");
            indexLayouts.push(res.data[i].layout.split("__")[0]);
          }
        }
        indexLayouts = indexLayouts.sort((a, b) => a - b);
        // console.log(indexLayouts.length);
        // console.log(indexLayouts);
        if (indexLayouts.length < 10) {
          for (let i = 0; i < indexLayouts.length; i++) {
            if (i == 0 && indexLayouts[i] > 1) break;
            // console.log(i + " - first if");
            if (i + 1 < indexLayouts.length) {
              if (parseInt(indexLayouts[i]) + 1 == indexLayouts[i + 1])
                continue;
              layoutIndex = parseInt(indexLayouts[i]) + 1;
              break;
            }
            layoutIndex = parseInt(indexLayouts[i]) + 1;
          }
        } else {
          alert("Erreur : Vous diffusez déjà le nombre maximum d'annonces.");
          props.closeModal();
          return false;
        }

        props.load();
        // console.log("------------ Layout Index ------------");
        // console.log(layoutIndex);
        // console.log("------------------------");

        layoutUpload(layoutIndex);
      }
    });
  };

  // Diffusion d'une vidéo par un MEDECIN sur la bibliothèque : PART 2
  // --> on crée le layout et on l'assigne à un display
  const layoutUpload = async (layoutIndex) => {
    let mediaName = props.annonce.title;
    // console.log("------------ PROPS Infos ------------");
    // console.log(props);
    // console.log("------------ MEDIA NAME ------------");
    // console.log(mediaName);
    let layoutName =
      layoutIndex + "__-__" + mediaName + "__" + cookies.user.userId;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      let description = "médecin";
      const formDataLayout = new FormData();
      formDataLayout.append("name", layoutName);
      formDataLayout.append("description", description);
      formDataLayout.append("resolutionId", 3);

      // console.log("Following formDataLayout content : ");
      // console.log(formDataLayout);

      const config2 = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      // creation d'un layout vide au bon format
      const resLayout = await Xibo.post("/layout", formDataLayout, config);

      const resLayoutbyParentId = await Xibo.get(
        "/layout?parentId=" +
          resLayout.data.layoutId +
          "&embed=regions,playlists"
      );

      // console.log(resLayoutbyParentId);

      // Assigne un background color au layout créé
      const paramsBackground = new URLSearchParams();
      paramsBackground.append("backgroundColor", "#ffffff");
      const resChangeLayoutBackground = await Xibo.put(
        "/layout/background/" + resLayoutbyParentId.data[0].layoutId,
        paramsBackground,
        config2
      );

      const formDataPlaylistAssign = new FormData();

      // Assigne une durée et un media a la playlist du layout
      let duration = 25; //TODO : demander à Anthony si on lève la limite pour les vidéos de la bibliothèque
      props.annonce.duration <= 25
        ? (duration = props.annonce.duration)
        : (duration = 25);
      // console.log(props.annonce.videoId);
      formDataPlaylistAssign.append("media[]", props.annonce.videoId);
      formDataPlaylistAssign.append("duration", duration);
      const resPlaylistAssign = await Xibo.post(
        "/playlist/library/assign/" +
          resLayoutbyParentId.data[0].regions[0].regionPlaylist.playlistId,
        formDataPlaylistAssign,
        config
      );

      // console.log("Following resPlayListAssign :");
      // console.log(resPlaylistAssign);

      // Publication du layout (fin de draft)
      const paramsPublish = new URLSearchParams();
      paramsPublish.append("publishNow", 1);
      const resPublishLayout = await Xibo.put(
        "/layout/publish/" + resLayoutbyParentId.data[0].parentId,
        paramsPublish,
        config2
      );

      // console.log("Following resPublishLayout :");
      // console.log(resPublishLayout);

      // Get le displaygroup du medecin pour diffuser sur toutes ces bornes
      //Fonctionnelle pour une diffusion globale des vidéos sur toutes les bornes du médecin, revoir le système pour une diffusion par borne
      let resDisplayGroup = await Xibo.get("/displaygroup");
      let groupIds = [];
      let indexVideo = layoutIndex;

      //formule pour toujours avoir un displayOrder impair
      let displayOrder = indexVideo * 2 - 1;
      // console.log("------------ DISPLAY ORDER ------------");
      // console.log(displayOrder);
      // console.log("------------------------");
      // On garde tous les id de chaque displaygroup de chaque borne du groupe de borne
      for (let i = 0; i < resDisplayGroup.data.length; i++) {
        groupIds.push(resDisplayGroup.data[i].displayGroupId);
      }

      // ajoute le layout sur chaque displaygroupId
      const formDataSchedule = new FormData();
      formDataSchedule.append("eventTypeId", 1);
      formDataSchedule.append("displayOrder", displayOrder);
      formDataSchedule.append("isPriority", 0);
      formDataSchedule.append("campaignId", resPublishLayout.data.campaignId);
      formDataSchedule.append("displayGroupIds[]", groupIds);
      formDataSchedule.append("dayPartId", 2);

      let scheduleRes = await Xibo.post("/schedule", formDataSchedule, config);
      // console.log("scheduleRes");
      // console.log(scheduleRes);

      if (scheduleRes.status === 201 || scheduleRes.status === 200) {
        let finalfunction = await DisplaysProofs(
          scheduleRes,
          mediaName,
          cookies,
          props.annonce
        );

        if (finalfunction === true) {
          props.unLoad("Annonce diffusée avec succès.");
        } else {
          props.unLoad(finalfunction);
        }
      } else {
        props.unLoad("Une erreure est survenue durant la diffusion.");
      }
    } catch (error) {
      props.unLoad(error);
    }
  };

  const getIp = async () => {
    const resultat = await GetIpClient();
    return resultat;
  };

  const setProof = async (scheduleRes, mediaName) => {
    const Xpress = axios.create({
      baseURL: `${XapiURL}/api`,
      withCredentials: true,
      crossDomain: true,
      // origin: "https://gestion.lisaconnect.fr",
      // origin: "*",
      origin: XapiURL,
    });
    let clientIp = await getIp();
    let videoName = mediaName;
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const time =
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds();

    try {
      Xpress.post(
        `/proof/new?userID=${scheduleRes.data.userId}&displayID=${scheduleRes.data.displayGroups[0].displayGroupId}&borne=${scheduleRes.data.displayGroups[0].displayGroupId}&ip=${clientIp}&video=${videoName}&date=${time}-${date}`
      ).then((resultat) => {
        console.log("on est dans le restultat la");
        if (resultat.status === 201 || resultat.status === 200) {
          console.log("Proof created");
        } else {
          console.log("Proof not created");
        }
      });
    } catch (error) {}
  };

  const handleClick = (element) => {
    setShowConfirm(true);
    setShowConfirm2(false);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    uploadAnnonce();
  };

  return (
    <div>
      {/* <Modal show={props.show} onHide={props.closeModal} centered size="sm"> */}
      <Modal show={showConfirm2} onHide={props.closeModal} centered size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{props.annonce.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "383px" }} className="modal-center">
            <VideoPlayer idVid="1" src={props.annonce.video} />
            <p>{displayTags()}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-center">
          <Button onClick={() => handleClick(props)}>
            Diffuser sur mes bornes
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmationModal
        show={showConfirm}
        closeModal={() => {
          setShowConfirm(false);
          setShowConfirm2(true);
          props.closeModal();
        }}
        confirm={handleConfirm}
        message="Êtes-vous sûr de vouloir diffuser cette annonce ?"
      />
    </div>
  );
}

export default VideoModal;
