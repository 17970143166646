import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  user_type: null,
  access_token: null,
  refresh_token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTokens: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    loginUser: (state, action) => {
      state.user = action.payload.DATA2;
      state.user_type = action.payload.DATA2?.userTypeId;
      state.access_token = action.payload.DATA1?.access_token;
      state.refresh_token = action.payload.DATA1?.refresh_token;
    },
    logoutUser: (state) => {
      state.user = null;
      state.user_type = null;
      state.access_token = null;
      state.refresh_token = null;
    },
  },
});

export const { setUser, setTokens, loginUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;
