import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import "../../assets/css/Docboard.css";
import EndPaper from "../../components/EndPaper";
// import Xibo from "../../APIs/api-xibo";
import PrinterStatus from "../../components/PrinterStatus";
import { rotateImg } from "../../services/Utils";
import ContactFormSimply from "../../components/Printers/ContactsForm_simplified";
import PrinterNotifs from "../../components/Printers/PrinterNotifs";
import { Fade } from "react-bootstrap";
import { ModalForms } from "../../components/GoogleForms/GoogleForm";

function AdminNotifs(props) {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showNotifsSect, setShowNotifsSect] = useState(false);
  const [showPrintertSect, setShowPrintertSect] = useState(false);

  useEffect(() => {}, []);

  return (
    <Container>
      <h1 className="mt-1 mb-5 ">Notifications Dashboard</h1>
      <Container className="mt-1">
        <Container
          className="btn G-title interfSectionsH3"
          onClick={() => {
            setShowContactForm(!showContactForm);
            rotateImg("carret-contactsList");
          }}
          id="contacts-title-btn"
        >
          <h2
            className="data-titles-tab"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span id="btn-title-span">Printers Contacts</span>
            <i
              className="bi bi-caret-down-fill rotate h4"
              id={"carret-contactsList"}
            ></i>
          </h2>
        </Container>
        {showContactForm ? <ContactFormSimply /> : <></>}
      </Container>
      <Container className="mt-1">
        <Container
          className="btn G-title interfSectionsH3"
          onClick={() => {
            setShowNotifsSect(!showNotifsSect);
            rotateImg("carret-notifsList");
          }}
          id="notifs-title-btn"
        >
          <h2
            className="data-titles-tab"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span id="btn-title-span">Printers Notifs</span>
            <i
              className="bi bi-caret-down-fill rotate h4"
              id={"carret-notifsList"}
            ></i>
          </h2>
        </Container>
        {showNotifsSect ? (
          <Fade in={showNotifsSect}>
            <PrinterNotifs />
          </Fade>
        ) : (
          <></>
        )}
      </Container>
      <ModalForms />
    </Container>
  );
}

export default AdminNotifs;
