import React, { useState, useEffect } from "react";
import "../../assets/css/printerDash.scss";

import { Button, Container, Modal } from "react-bootstrap";
import DocPrinterStatus from "../../components/Doc/DocPrinterStatus";
import ContactFormSimply from "../../components/Printers/ContactsForm_simplified";
import BornesFormSimplyV2 from "../../components/Bornes/BornesForm_simplified_V2";

import { useDispatch, useSelector } from "react-redux";
import PrinterServices from "../../services/PrinterServices";
import {
  setPrintersContacts,
  setPrintersStatus,
} from "../../store/slices/kioskSlice";

import "../../assets/css/DocInfos.scss";

function DocInfos() {
  const dispatch = useDispatch();
  const { printersContacts, printerAddress } = useSelector(
    (state) => state.kiosk
  );

  const [show, setShow] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleClose = () => {
    setShow(false);
    setCounter(0);
  };
  const handleShow = () => setShow(true);

  const handleEsc = () => setCounter(counter + 1);

  useEffect(() => {
    // console.log("printersContacts", printersContacts);
    if (printersContacts && Object.keys(printersContacts).length > 0) {
      if (
        printersContacts.etablissement?.includes("LisaConnect") ||
        printersContacts.etablissement?.includes("default") ||
        printersContacts.contact_principal?.email?.includes("lisaconnect")
      ) {
        setShowContacts(true);
        // handleShow();
      } else {
        setShowContacts(false);
        // handleClose();
      }
    }
  }, [printersContacts]);

  useEffect(() => {
    // console.log("printerAddress", printerAddress);
    if (printerAddress && Object.keys(printerAddress).length > 0) {
      if (printerAddress.status === "waiting") {
        setShowAddress(true);
      } else {
        setShowAddress(false);
      }
      // setShowAddress(true);
    } else {
      setShowAddress(false);
    }
  }, [printerAddress]);

  useEffect(() => {
    counter && counter > 8 && handleClose();
  }, [counter]);

  useEffect(() => {
    if (showContacts || showAddress) {
      handleShow();
    } else {
      handleClose();
    }
  }, [showContacts, showAddress]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="docInfosPopup"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>Informations à remplir</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            Afin de pouvoir utiliser votre borne{" "}
            <span onClick={handleEsc}>Lisa</span> , il est impératif de
            renseigner les informations suivantes.
          </p>
        </div>

        {showContacts && printersContacts && (
          <Container className="mt-4 mb-4">
            <h5>Référents de l'imprimante</h5>

            <Container className="mb-4">
              <ContactFormSimply mode="doc" />
            </Container>
          </Container>
        )}

        {showAddress && printerAddress && (
          <Container className="mt-4 mb-4">
            <h5>Informations de l'établissement</h5>

            <Container>
              <BornesFormSimplyV2 mode="doc" />
            </Container>
          </Container>
        )}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary">Understood</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default DocInfos;
