import React, { useRef, useState } from "react";
import { Button, Form, ListGroup, Modal, Row, Toast } from "react-bootstrap";
import { FaCartPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import GeoServices from "../../services/GeoLocalisation";
import { Xpress } from "../../APIs/api-xibo";
import PrinterServices from "../../services/PrinterServices";

export default function PrinterShippingModalV2() {
  const { kiosks, printerAddress, printersContacts } = useSelector(
    (state) => state.kiosk
  );
  const [show, setShow] = useState(false);

  const [isSelected, setIsSelected] = useState("");

  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const searchTimerRef = useRef(null); // Utilisation de useRef pour le timer

  const emptyFormData = {
    etablissement: "",
    adressType: "default",
    adresse: "",
  };

  const [formData, setFormData] = useState(emptyFormData);

  const [showT, setShowT] = useState(false);

  const handleSearch = (e) => {
    const newQuery = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      adresse: newQuery,
    }));
    if (newQuery.length > 2) {
      clearTimeout(searchTimerRef.current); // Annuler le timer précédent
      searchTimerRef.current = setTimeout(
        async () =>
          setFilteredAddresses(
            // await GeoServices.SearchAddressOpenMap(newQuery)
            await GeoServices.SearchAddressDataGouv(newQuery)
          ),
        500
      ); // Définir un nouveau timer
    }
  };

  function RenderSearchOpenMap() {
    return filteredAddresses.map((address, index) => (
      <ListGroup.Item key={index}>
        <span
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              adresse: address.display_name || address.properties.label,
            }));
            setFilteredAddresses([]);
          }}
        >
          {address.display_name || address.properties.label}
        </span>
      </ListGroup.Item>
    ));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelect = (e) => {
    setIsSelected(e);
    if (e === "default") {
      setFormData((prevState) => ({
        ...prevState,
        adresse: printerAddress?.adresse,
        adressType: e,
        etablissement: printerAddress?.etablissement,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        adressType: e,
      }));
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const AskForPaper = (e) => {
    e.preventDefault();

    if (isSelected === "") {
      alert("Veuillez choisir une adresse de livraison");
    } else {
      SendPaperDemand({
        kioskTarget: kiosks[0].display,
        kioskTitle: kiosks[0].deviceName
          ? `${kiosks[0].display} (${kiosks[0].deviceName})`
          : kiosks[0].display,
        address: formData.adresse,
        etablissement: formData.etablissement,
        contacts: PrinterServices.formatContactForEmail(printersContacts),
      });
    }
  };

  async function SendPaperDemand({
    kioskTarget,
    kioskTitle,
    address,
    etablissement,
    contacts,
  }) {
    try {
      const asked = await Xpress.post(`/printer/ask-paper/${kioskTarget}`, {
        borne: kioskTitle,
        address: address,
        etablissement: etablissement,
        contacts: contacts,
      });
      // alert("Demande de papier envoyée");
      setShowT(true);
      setShow(false);
      resetForm();
    } catch (error) {
      console.error(error);
      alert("Erreur lors de l'envoi de la demande");
    }
  }

  function resetForm() {
    setFormData(emptyFormData);
    setIsSelected("");
  }

  return (
    <>
      <div className="mt-3 mb-0 paper-btn" onClick={() => setShow(!show)}>
        <FaCartPlus size={26} color="#F8B5A8" /> <span>Demander du papier</span>
      </div>

      <Modal
        scrollable
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Livraison de papier d'impression</Modal.Title>
        </Modal.Header>
        <Form onSubmit={AskForPaper}>
          <Modal.Body>
            <Form.Group controlId="formBasicCheckbox1">
              <div>
                <Form.Check
                  type="radio"
                  label={`Addresse par defaut`}
                  onChange={() => handleSelect("default")}
                  name="formVertRadios"
                  id="formDefaultAddress"
                  required
                />
                <div className="p-4">
                  <p className="m-1">{printerAddress?.etablissement}</p>
                  <p className="p-0">{printerAddress?.adresse}</p>
                </div>
              </div>
              <hr />
              <Form.Check
                type="radio"
                label={`Adresse personnalisée`}
                onChange={() => handleSelect("custom")}
                name="formVertRadios"
                id="formCustomAddress"
                required
              />
              {isSelected === "custom" ? (
                <div className="p-4 py-0">
                  <Form.Control
                    name="etablissement"
                    className="mb-3"
                    size="sm"
                    type="text"
                    placeholder="Destinaire"
                    value={formData.etablissement}
                    onChange={handleChange}
                    required
                  />

                  <Form.Control
                    name="adresse"
                    size="sm"
                    type="text"
                    placeholder="Adresse de livraison"
                    value={formData.adresse}
                    onChange={handleSearch}
                    required
                  />
                  {RenderSearchOpenMap()}
                </div>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox2" size="sm"></Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Créer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Toast
        show={showT}
        onClose={() => setShowT(false)}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          top: "70px",
          right: 0,
          background: "rgb(200,255,213)",
        }}
      >
        <Toast.Header>Demande de papier</Toast.Header>
        <Toast.Body>
          Votre demande de papier a bien été transmise.
          <br />
          Ils vous seront envoyées dans les plus brefs délais.
        </Toast.Body>
      </Toast>
    </>
  );
}
