import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import PrinterShippingModalV2 from "./PrinterShippingModalV2";

export default function DocPrinterStatus() {
  const { printersStatus } = useSelector((state) => state.kiosk);
  const [paperInfo, setPaperInfo] = useState(null);
  const [printerInfo, setPrinterInfo] = useState(null);

  useEffect(() => {
    if (
      printersStatus &&
      printersStatus.paper &&
      printersStatus.paper.data &&
      printersStatus.paper.data.length > 0
    ) {
      setPaperInfo(printersStatus.paper.data[0]);
    }
    if (
      printersStatus &&
      printersStatus.printer &&
      printersStatus.printer.data &&
      printersStatus.printer.data.length > 0
    ) {
      setPrinterInfo(printersStatus.printer.data[0]);
    }
  }, [printersStatus]);

  const GetPrinterBadge = (status) => {
    switch (status.Statut) {
      case "ON":
        return BadgeTemplate(status, "success");
      case "OFF":
        return BadgeTemplate(status, "danger");
      case " NORMAL ":
        return BadgeTemplate(status, "success");
      case "PAPIER PRESQUE VIDE":
        return BadgeTemplate(status, "warning");
      case "PAPIER VIDE":
        return BadgeTemplate(status, "danger");
      default:
        return "Pas d'informations pour le moment";
    }
  };

  function combineDateAndTime(dateStr, timeStr) {
    // console.log("initial date - time", dateStr + " " + timeStr);

    // Extraire les parties de la date
    let [day, month, year] = dateStr.split("/").map(Number);
    // Extraire les parties de l'heure
    let [hours, minutes] = timeStr.split(":").map(Number);

    // Créer un objet Date dans le fuseau horaire local (assumé comme UTC+2)
    let dateFromVars = new Date(year, month - 1, day, hours, minutes);

    // Obtenir l'heure actuelle et l'ajuster à UTC+2
    let currentDate = new Date();
    currentDate.setMinutes(
      currentDate.getMinutes() + currentDate.getTimezoneOffset() + 120
    ); // Ajouter l'offset local + 120 minutes pour UTC+2

    // Calculer la différence en millisecondes
    let differenceInMs = currentDate - dateFromVars;
    // Convertir la différence en heures
    let differenceInHours = differenceInMs / (1000 * 60 * 60);

    return {
      dateFromVars: dateFromVars.toString(),
      currentDate: currentDate.toString(),
      differenceInHours: differenceInHours,
    };
  }

  function DoNotPanic(differenceInHours, varP, txt) {
    const dureeLimite = 3; // 3 heures
    let finalStatus = { text: txt, color: varP };

    if (
      differenceInHours <= dureeLimite &&
      (varP == "danger" || varP == "warning")
    ) {
      finalStatus.color = "success";
      finalStatus.text = "ON";
    }
    return finalStatus;
  }

  const BadgeTemplate = (status, varP) => {
    const { dateFromVars, currentDate, differenceInHours } = combineDateAndTime(
      status.Date,
      status.Time
    );
    let finalStatus = DoNotPanic(differenceInHours, varP, status.Statut);

    return (
      <Col>
        <Badge variant={finalStatus?.color}>{finalStatus?.text}</Badge>
        {/* <p>
          {status?.Date} - {status?.Time}
        </p> */}
        {finalStatus?.text == "PAPIER VIDE" ||
        finalStatus?.text == "PAPIER PRESQUE VIDE" ? (
          <PrinterShippingModalV2 />
        ) : (
          <></>
        )}
      </Col>
    );
  };

  return (
    <Container className="mt-1 mb-4">
      <h2>Status de l'imprimante</h2>
      <Container as={Row} className="text-center">
        <Col xs={5} className="contact-form-actions status-blocs">
          <h5>Statut de l'imprimante</h5>
          <p className="pt-2 mb-0">
            {printerInfo
              ? GetPrinterBadge(printerInfo)
              : "Pas d'informations pour le moment"}
          </p>
        </Col>
        <Col xs={5} className="contact-form-actions status-blocs">
          <h5>Statut du papier</h5>
          <p className="pt-2 mb-0">
            {paperInfo
              ? GetPrinterBadge(paperInfo)
              : "Pas d'informations pour le moment"}
          </p>
        </Col>
      </Container>
    </Container>
  );
}
