import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { useCookies } from "react-cookie";

import routes from "../routes";
import { useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

function NavTop(props) {
  const [cookies] = useCookies(["user"]);
  const { user, user_type } = useSelector((state) => state.auth);
  const { kiosks, displayGroups } = useSelector((state) => state.kiosk);

  const onglets = (
    <Nav variant="tabs">
      {routes.map((prop, key) => {
        // console.log(cookies);
        //On vérifie grâce aux cookies que l'utilisateur est abonné, sinon on ne lui affiche pas la page
        if (prop.layout + prop.path === "/adboard/diffusion") {
          if (cookies.user === undefined) return;
          else if (cookies.user.userName !== "xibo_admin") {
            if (
              cookies.user.groups[0].group === "Annonceurs" &&
              cookies.user.ref1 !== null &&
              cookies.user.ref2 !== null
            ) {
              let splitDateDebut = cookies.user.ref1.split("/");
              let strDateDebut =
                splitDateDebut[2] +
                "-" +
                splitDateDebut[1] +
                "-" +
                splitDateDebut[0];
              let dateDebut = new Date(strDateDebut);

              let splitDateFin = cookies.user.ref2.split("/");
              let strDateFin =
                splitDateFin[2] + "-" + splitDateFin[1] + "-" + splitDateFin[0];
              let dateFin = new Date(strDateFin);

              if (dateDebut > new Date() || dateFin <= new Date()) {
                return;
              }
            } else {
              return;
            }
          }
        }
        if (prop.layout !== props.layout) return;
        let isActive = false;
        if (props.pageName === prop.layout + prop.path) isActive = true;

        return (
          <Nav.Item key={key}>
            <Nav.Link
              active={isActive}
              eventKey={key}
              href={prop.layout + prop.path}
            >
              {prop.name}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );

  const displayDataAccount = () => {
    if (cookies.user === undefined) return;
    else if (cookies.user.userName === "xibo_admin") return;
    // else if (cookies.user.userName === "media_lisa") return;
    else if (cookies.user.userTypeId === 2) return;
    else if (cookies.user.groups[0].group === "Annonceurs") {
      if (cookies.user.ref1 !== null && cookies.user.ref2 !== null) {
        let splitDate = cookies.user.ref2.split("/");
        let strDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
        let date = new Date(strDate);
        if (date > new Date()) {
          // return <span>Votre abonnement se termine le : <b>{cookies.user.ref2}</b></span>;
          return (
            <span>
              Abonnement : du <b>{cookies.user.ref1}</b> au{" "}
              <b>{cookies.user.ref2}</b>
            </span>
          );
        } else {
          return "Abonnez-vous pour accéder aux bornes disponibles";
        }
      } else {
        return "Abonnez-vous pour accéder aux bornes disponibles";
      }
    } else if (cookies.user.groups[0].group === "Medecins") {
      if (kiosks && kiosks.length === 1) {
        let nameDisplay = kiosks[0]?.display?.split("-");
        let nameDevice = kiosks[0]?.deviceName?.split("-");
        return (
          <span style={{ fontSize: "smaller" }}>
            {kiosks[0]?.clientType === "windows" ? "Borne  : " : "Affichage : "}
            <b>
              {(nameDisplay && nameDisplay[nameDisplay.length - 1]) ||
                (nameDevice && nameDevice[nameDevice.length - 1]) ||
                kiosks[0]?.display ||
                kiosks[0]?.deviceName}
            </b>
          </span>
        );
      } else if (kiosks && kiosks.length > 1) {
        return (
          <span>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              key={1}
              placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Title as="h3">Vos Affichages</Popover.Title>
                  <Popover.Content>
                    {kiosks.map((ki, index) => (
                      <p key={index}>{ki?.display || ki?.deviceName}</p>
                    ))}
                  </Popover.Content>
                </Popover>
              }
            >
              <span style={{ fontSize: "smaller" }}>
                {kiosks.length} Affichages
              </span>
            </OverlayTrigger>
          </span>
        );
      }
    }

    return;
  };

  /*const dropdown = (
        <NavDropdown title={props.pageName}>
            {routes.map((prop, key) => {
                if(prop.layout !== props.layout) return;

                let isActive = false;
                if(props.pageName === prop.name) isActive = true;

                return (
                    <NavDropdown.Item active={isActive}>{prop.name}</NavDropdown.Item>
                );
            })}
        </NavDropdown>
    );*/

  return (
    <Navbar
      className="bg-light justify-content-between border-bottom"
      sticky="top"
    >
      {onglets}
      <div>
        <span className="mr-5">{displayDataAccount()}</span>
        <Button
          onClick={(e) => props.onClick(e)}
          style={{ backgroundColor: "#f8b5a8", borderColor: "#f8b5a8" }}
        >
          Déconnexion
        </Button>
      </div>
    </Navbar>
  );
}

export default NavTop;
