import React from "react";
import { XiboURL } from "../APIs/api-xibo";
import Xibo from "../APIs/api-xibo";
import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import BootstrapTable from "react-bootstrap-table-next";
import ConfirmationModal from "./ConfirmationModal";
import LoadingModal from "./LoadingModal";
import "../assets/css/ManageAnnonces.scss";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { rotateImg } from "../services/Utils";
function ManageAnnonces(data) {
  const [schedules, setSchedules] = useState([]);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  const [dataSet, setDataSet] = useState([]);
  const [title, setTitle] = useState();

  // console.log(data.data);

  const closeConfirm = () => {
    setSelectValue("");
    setShowConfirm(false);
  };

  const handleClick = (e, data = null) => {
    setShowConfirm(false);
    stopSchedule(selectValue);
  };
  useEffect(() => {
    // getDG();
    // console.log(dataSet);
    getSchedule(data.data);
    // console.log(data.data);
  }, []);

  //   useEffect(() => {
  //     displayData();
  //   }, [schedules]);

  async function getDG() {
    Xibo.get("/display?start=0&length=500")
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        // console.log(response.data);
        let tg = response.data;
        // console.log(tg);
        // setDisplaysG(response.data);
        // getSchedule(response.data);
        getSchedule(response.data);
      })
      .finally(() => {
        // getSchedule();
        // getSchedule(groups);
      });
  }

  async function getSchedule(data) {
    // setShowLoading(true);
    let todo = [];
    // console.log(data);
    const today = new Date();

    // console.log(data);
    let dataF = {
      displays: {
        displayGroup: data.displayGroup,
        displayGroupId: data.displayGroupId,
      },
      schedules: [],
      layouts: [],
      events: [],
    };
    setTitle(
      data.displayGroupId + " - " + data.display + `  [${data.deviceName}]`
    );
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.toLocaleTimeString();
    await Xibo.get(
      `/schedule/${data.displayGroupId}/events?date=` +
        date +
        "&start=0&length=1000&publishedStatus=Published"
    )
      .then((response) => {
        let dataSet = [];
        dataF.schedules = response.data;
        dataF.layouts = response.data.layouts;
        dataF.events = response.data.events;

        if (dataF.layouts) {
          for (const obj in dataF.layouts) {
            if (Object.hasOwnProperty.call(dataF.layouts, obj)) {
              // if (dataF.layouts[obj].description != "médecin") {
              if (!dataF.layouts[obj].description?.includes("médecin")) {
                const element = dataF.layouts[obj];
                dataF.events.find((item) => {
                  if (
                    item.layoutId == element.layoutId &&
                    element.layout !== "Code borne"
                  ) {
                    let btn = (
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          //   handleClick(e, dataF);
                          // console.log(item.eventId);
                          setSelectValue(item.eventId);
                          setShowConfirm(true);
                          //   stopSchedule(item.eventId);
                        }}
                        key={item.eventId}
                      >
                        STOP
                        {/* <i class="bi bi-calendar-x-fill"></i> */}
                      </Button>
                    );
                    // console.log("------------------------------------------");
                    // console.log("1235");
                    // console.log(item.fromDt);
                    // console.log(item.toDt);
                    // console.log(
                    //   "------------------!!...!!!------------------------"
                    // );
                    // console.log(item);
                    // console.log(item.displayOrder);
                    // console.log(
                    //   "------------------!!...!!!------------------------"
                    // );

                    item.fromDt = new Date(item.fromDt * 1000).toLocaleString();
                    item.toDt = new Date(item.toDt * 1000).toLocaleString();
                    item = {
                      ...item,
                      layoutId: element.layoutId,
                      ownerId: element.ownerId,
                      owner: element.owner,
                      campaigneId: element.campaigneId,
                      layout: element.layout,
                      validate: btn,
                      displayOrder: item.displayOrder,
                    };
                    // console.log(item);
                    dataSet.push(item);
                  }
                });
              }
            }
          }
        }
        // console.log(dataSet);
        setShowLoading(false);
        setDataSet(dataSet);
      })
      .finally(() => {});

    // console.log(todo);
  }

  function stopSchedule(target) {
    // console.log("stop");
    // setShowLoading(true);
    try {
      Xibo.delete(`/schedule/${target}`).then((response) => {
        // setShowLoading(false);
        alert("Event stopped");
        window.location.reload(false);
      });
    } catch (error) {
      alert("An error occured");
    }
  }

  function ChangeVisibility(id) {
    // if (
    //   document.getElementById(`collapse` + id).style.visibility == "collapse"
    // ) {
    //   document.getElementById(`collapse` + id).style.visibility = "visible";
    // } else {
    //   document.getElementById(`collapse` + id).style.visibility = "collapse";
    // }
    if (document.getElementById(`collapse` + id).style.display == "none") {
      document.getElementById(`collapse` + id).style.display = "block";
    } else {
      document.getElementById(`collapse` + id).style.display = "none";
    }
  }

  const columns = [
    {
      dataField: "campaignId",
      text: "Event ID",
      headerStyle: { width: "60px" },
    },
    { dataField: "campaign", text: "Event" },
    {
      dataField: "layoutId",
      text: "Layout ID",
      headerStyle: { width: "80px" },
    },
    { dataField: "fromDt", text: "From Date", headerStyle: { width: "120px" } },
    { dataField: "toDt", text: "To Date", headerStyle: { width: "120px" } },
    { dataField: "ownerId", text: "Owner ID", headerStyle: { width: "80px" } },
    { dataField: "owner", text: "Owner" },
    {
      dataField: "displayOrder",
      text: "Order",
      // formatter: dataFormatterSM3,
      headerStyle: { width: "80px" },
    },

    { dataField: "validate", text: "#", headerStyle: { width: "90px" } },
  ];

  function dataFormatterSM3(cell, row) {
    // console.log("cell", cell);
    return (
      <span style={{ fontSize: "smaller", textAlign: "center" }}>{cell}</span>
    );
  }

  return (
    <div className="annonces-main">
      {/* <ul id="target-div"></ul> */}
      <div className="annonces-sections">
        {/* <button
          className="btn"
          type="button"
          onClick={() => {
            console.log(dataSet);
            setShow(true);
            rotateImg("carret-" + data.data.displayGroupId);
            ChangeVisibility(data.data.displayGroupId);
          }}
        >
          <h5
            className="data-titles-tab"
            style={
              dataSet.length > 0 ? { color: "black" } : { color: "#a5a5a5" }
            }
          >
            {title}
            <span>
              <i
                className="bi bi-caret-down-fill rotate"
                id={"carret-" + data.data.displayGroupId}
              ></i>
            </span>
          </h5>
        </button> */}
        <button
          className="btn btn-show-datatable"
          type="button"
          onClick={() => {
            // console.log(dataSet);
            setShow(true);
            rotateImg("carret-" + data.data.displayGroupId);
            ChangeVisibility(data.data.displayGroupId);
          }}
        >
          <Col
            as={"h5"}
            className="data-titles-tab"
            style={
              dataSet.length > 0 ? { color: "black" } : { color: "#a5a5a5" }
            }
          >
            {title}
          </Col>
          <span>
            <i
              className="bi bi-caret-down-fill rotate"
              id={"carret-" + data.data.displayGroupId}
            ></i>
          </span>
        </button>
        {/* <Col
          as="h5"
          xs={12}
          className="data-titles-tab"
          style={dataSet.length > 0 ? { color: "black" } : { color: "#a5a5a5" }}
        >
          <button
            className="btn"
            type="button"
            onClick={() => {
              console.log(dataSet);
              setShow(true);
              rotateImg("carret-" + data.data.displayGroupId);
              ChangeVisibility(data.data.displayGroupId);
            }}
          >
            <span>{title}</span>
            <i
              className="bi bi-caret-down-fill rotate"
              id={"carret-" + data.data.displayGroupId}
              style={{ width: "auto" }}
            ></i>
          </button>
        </Col> */}
        <div
          className=""
          id={`collapse` + data.data.displayGroupId}
          // style={{ visibility: "collapse" }}
          style={{ display: "none" }}
        >
          {dataSet.length > 0 ? (
            <BootstrapTable
              keyField="eventId"
              bootstrap4
              data={dataSet}
              columns={columns}
              striped
              key={title}
            />
          ) : (
            <span className="font-italic">
              Aucune annonce en cours de diffusion pour cette Borne
            </span>
          )}
          {/* <BootstrapTable
            keyField="id"
            data={dataSet}
            columns={columns}
            striped
          /> */}
          <ConfirmationModal
            show={showConfirm}
            closeModal={closeConfirm}
            message="Arrêter la diffusion de l'annonce sélectionnée ?"
            confirm={handleClick}
          />
          <LoadingModal show={showLoading} />
        </div>
      </div>
    </div>
  );
}

export default ManageAnnonces;
