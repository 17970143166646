import axios from "axios";
import { ListGroup } from "react-bootstrap";

const GeoServices = {
  SearchAddressOpenMap: async function (query) {
    if (typeof query !== "string" || !query.trim()) return;

    try {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${query}&format=json&countrycodes=fr`
      );
      return data;
    } catch (error) {
      console.error("Error while fetching addresses:", error);
      return null;
    }
  },

  SearchAddressDataGouv: async function (query) {
    if (typeof query !== "string" || !query.trim()) return;

    try {
      const { data } = await axios.get(
        `https://api-adresse.data.gouv.fr/search?q=${query}&limit=5`
      );
      return data?.features;
    } catch (error) {
      console.error("Error while fetching addresses:", error);
      return null;
    }
  },
};

export default GeoServices;
