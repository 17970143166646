import React, { useState, useEffect } from "react";
import "../../assets/css/printerDash.scss";

import { Container } from "react-bootstrap";
import DocPrinterStatus from "../../components/Doc/DocPrinterStatus";
import ContactFormSimply from "../../components/Printers/ContactsForm_simplified";
import { useDispatch, useSelector } from "react-redux";
import PrinterServices from "../../services/PrinterServices";
import {
  setPrinterAddress,
  setPrintersContacts,
  setPrintersStatus,
} from "../../store/slices/kioskSlice";
import BornesFormSimplyV2 from "../../components/Bornes/BornesForm_simplified_V2";

function DocPrinterDash() {
  const dispatch = useDispatch();
  const { kiosks } = useSelector((state) => state.kiosk);

  async function GetPrinterContacts() {
    const printerContacts = await PrinterServices.GetPrinterInfo(
      // kiosks[0].display || kiosks[0].deviceName
      kiosks[0].display
    );

    if (printerContacts.success && printerContacts.data) {
      console.log("printerContacts.data", printerContacts.data);
      dispatch(setPrintersContacts(printerContacts.data));
    } else {
      console.error("error", printerContacts);
    }
  }

  async function GetPrinterInfos() {
    const printerStatus = await PrinterServices.GetPrinterStatus(
      kiosks[0].display,
      kiosks[0].deviceName
    );
    if (printerStatus.success && printerStatus.data) {
      dispatch(setPrintersStatus(printerStatus.data));
    } else {
      console.error("error", printerStatus);
    }
  }

  async function GetPrinterAddress() {
    const printerAddress = await PrinterServices.GetPrinterAddress(
      kiosks[0].display,
      kiosks[0].deviceName
    );

    if (printerAddress.success && printerAddress.data) {
      console.log("printerAddress.data.data", printerAddress.data.data);

      dispatch(setPrinterAddress(printerAddress.data.data));
    } else {
      console.error("error", printerAddress);
    }
  }

  useEffect(() => {
    if (kiosks.length === 0) {
    } else if (kiosks.length === 1) {
      GetPrinterContacts();
      GetPrinterInfos();
      GetPrinterAddress();
 
    } else if (kiosks.length > 1) {
      console.log("kiosks length > 1");
    }
  }, [kiosks]);

  // useEffect(() => {
  //   UpdateAccessState(setAccessToken);
  //   RefreshAccessToken();
  // }, []);

  return (
    <Container className="printerDashDoc">
      <h1 className="mt-1 mb-5 ">Administration</h1>
      <DocPrinterStatus />
      <Container className="mt-2 mb-4">
        <h2>Référents de l'imprimante</h2>
        <Container className="mb-4">
          <ContactFormSimply mode="doc" />
        </Container>
      </Container>
      <Container className="mt-2 mb-4">
        <h2>Informations de l'établissement</h2>
        <Container>
          <BornesFormSimplyV2 mode="doc" />
        </Container>
      </Container>
    </Container>
  );
}

export default DocPrinterDash;
